import React, { useEffect, useState } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Formik, Form, Field } from "formik";
import { TextField } from 'formik-material-ui';
import axios from "axios";
import { format } from "date-fns";
import {
  CssBaseline,
  Button,
  Avatar,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  LinearProgress,
  makeStyles
} from "@material-ui/core";
import { Redirect } from 'react-router-dom';
//components
import Notification from "../components/Notification";
import Popup from '../components/Popup';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://partnerportal.bevootech.com">
        Bevootech
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

/* AUTHORIZATION TOKEN BEARER
const accessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjQyNTAzMTcsImV4cCI6MTYyNDI1MDQzNywianRpIjoiNU1TdlhiNnpsSmZzcWdkaTdHdEJ2YyIsInN1YiI6bnVsbH0.-nm12HSpak8xzonJ4rlGvZqu83AAem830yIlAUZp3Rk";

axios.interceptors.request.use(
  config => {
    config.headers.authorization = `Bearer ${accessToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)
*/

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: 'url(/imgs/login-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  MuiDialogPaperWidthMd: {
    width: '350px'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [user_id, setUser_id] = useState("");
  const [username, setUsername] = useState("");
  const [user_level, setUser_level] = useState("");
  const [token, setToken] = useState("");
  const [forgotPopup, setForgotPopup] = useState(false);
  const [pinPopup, setPinPopup] = useState(false);
  const [pinSubmit, setPinSubmit] = useState(false);
  const [requestPin, setRequestPin] = useState(false);
  const [pinNotify, setPinNotify] = useState("");
  const [pinEmail, setPinEmail] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [forgotNotify, setForgotNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const initVal = {
    username: "",
    password: "",
  };

  const pageTokenCheck = () => {
    const domainUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_DOMAIN_URL_PROD : process.env.REACT_APP_DOMAIN_URL;

    let token = localStorage.getItem("token");
    if (!token) {
      document.body.classList.add("login-page");
      window.location.replace(`${domainUrl}`);
    }
  }

  const loginTokenCheck = () => {
    const homeUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_HOME_URL_PROD : process.env.REACT_APP_HOME_URL;

    let token = localStorage.getItem("token");
    if (token) {
      document.body.classList.remove("login-page");
      window.location.replace(`${homeUrl}`);
    }
  }

  //close pin popup
  const closepPinPopupShow = () => {
    setPinPopup(false);
    setPinSubmit(false);
    setRequestPin(false);
  }

  //request new pin
  const requestPinPopup = () => {
    let pinExpiry = format(new Date(new Date().getTime() + (60 * 3000)), 'yyyy-MM-dd HH:mm:ss'); //expiry

    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/newpin`, {
      email: pinEmail,
      pinExpiry: pinExpiry,
    })
      .then(res => {
        document.getElementById("pin-notify-wrap").classList.add("new-pin");

        setPinNotify("New PIN has been sent successfully");
        console.log(res.data);
      })
      .catch(error => {
        console.log(error)
      });
  }

  //show forgot password popup
  const forgotPopupShow = () => {
    setForgotPopup(true);
  }

  //close forgot password popup
  const closeForgotPopup = () => {
    setForgotPopup(false);
  }

  useEffect(() => {
    loginTokenCheck();
    pageTokenCheck();
    console.log(" process.env.NODE_ENV " + process.env.NODE_ENV);
  }, []);

  return (
    <React.Fragment>
      <Notification notify={forgotNotify} setNotify={setForgotNotify} />

      <Popup
        openPopup={pinPopup}
        setOpenPopup={setPinPopup}
        title={
          <div className="popup-title-bar">
            PIN AUTHENTICATION
            <Button
              onClick={() => {
                closepPinPopupShow();
              }}
              className="pop-close"
            >
              x
            </Button>
          </div>
        }
      >
        <Formik
          initialValues={{ pin: "" }}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              let timeSubmitted = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
              axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/pin`, {
                pin: values.pin,
                timeSubmitted: timeSubmitted
              })
                .then(res => {
                  setPinSubmit(true);
                  if (res.data == 1) {
                    //set state to false
                    setSubmitting(false);
                    setPinPopup(false);
                    setPinSubmit(false);
                    setRequestPin(false);

                    //set localstorage
                    localStorage.setItem("user_id", user_id);
                    localStorage.setItem("username", username);
                    localStorage.setItem("user_level", user_level);
                    localStorage.setItem("token", token);

                    axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/${user_id}`).then((res2) => {
                      //variables
                      let admin_id = res2.data[0].admin_id;
                      let email = res2.data[0].email;
                      let name = res2.data[0].name;
                      let company_name = res2.data[0].company_name;
                      let mobile = res2.data[0].mobile;
                      let address = res2.data[0].address;

                      localStorage.setItem("name", name);
                      localStorage.setItem("admin_id", admin_id);
                      localStorage.setItem("name", name);
                      localStorage.setItem("email", email);
                      localStorage.setItem("company_name", company_name);
                      localStorage.setItem("mobile", mobile);
                      localStorage.setItem("address", address);

                      //set profile pic , redirect page
                      axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/userphoto/${user_id}`).then((res) => {
                        let userPhotoCheck = res.data.substring(0, 4);

                        if (userPhotoCheck == "data") {
                          localStorage.setItem("user_photo", res.data);
                          window.location.reload();
                        }
                        else {
                          localStorage.setItem("user_photo", "/imgs/profile-pic-lg.jpg");
                          window.location.reload();
                        }

                        setSubmitting(false);

                      });

                    });

                  }
                  else if (res.data == "expired") {
                    document.getElementById("pin-notify-wrap").classList.remove("new-pin");
                    setSubmitting(false);
                    setPinNotify("Your PIN is already expired");
                    setRequestPin(true);
                  }
                  else if (res.data == "does not exist") {
                    document.getElementById("pin-notify-wrap").classList.remove("new-pin");
                    setSubmitting(false);
                    setPinNotify("Your PIN does not exist");
                    setRequestPin(true);
                  }
                  else {
                    document.getElementById("pin-notify-wrap").classList.remove("new-pin");
                    setSubmitting(false);
                    setPinPopup(false);
                    setPinNotify("Your PIN is invalid");
                    setRequestPin(true);
                  }
                })
                .catch(error => {
                  document.getElementById("pin-notify-wrap").classList.remove("new-pin");
                  setSubmitting(false);
                  setPinPopup(false);
                  setPinNotify("Invalid Request");
                  console.log(error)
                });

            }, 500);
          }}
          //validation
          validate={values => {
            const errors: Partial<Values> = {};
            if (!values.pin) {
              errors.pin = 'Required';
            }
            return errors;
          }}
        >

          {({ submitForm, isSubmitting }) => (
            <Form className='viewEditForm'>
              {isSubmitting && <LinearProgress />}

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="text"
                    label="PIN"
                    name="pin"
                  />
                  {pinSubmit && <p id="pin-notify-wrap">{pinNotify}</p>}
                </Grid>
                <Grid item xs={12} className="buttons-wrap pin-wrap">
                  {requestPin &&
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => { requestPinPopup() }}
                    >
                      Request New PIN
                    </Button>
                  }
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      </Popup>
      <Popup
        openPopup={forgotPopup}
        setOpenPopup={setForgotPopup}
        title={
          <div className="popup-title-bar">
            FORGOT PASSWORD
            <Button
              onClick={() => {
                closeForgotPopup();
              }}
              className="pop-close"
            >
              x
            </Button>
          </div>
        }
      >
        <Formik
          initialValues={{ email: "" }}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {

              let modifacationDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

              axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/forgot`, {
                datetime_of_modification: modifacationDate,
                email: values.email
              })
                .then(res => {
                  if (res.data == 1) {
                    setSubmitting(false);
                    setForgotPopup(false);
                    setForgotNotify({
                      isOpen: true,
                      message: `Your new password has been sent to your email address.`,
                      type: "success",
                    });
                    console.log('111');
                  }
                  else {
                    setSubmitting(false);
                    setForgotPopup(false);
                    setForgotNotify({
                      isOpen: true,
                      message: `Your email does not exist.`,
                      type: "error",
                    });
                    console.log('222');
                  }
                })
                .catch(error => {
                  setSubmitting(false);
                  setForgotPopup(false);
                  setForgotNotify({
                    isOpen: true,
                    message: `Invalid Request.`,
                    type: "error",
                  });
                  console.log('333');
                  console.log(error)
                });

            }, 500);
          }}
          //validation
          validate={values => {
            const errors: Partial<Values> = {};
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
        >

          {({ submitForm, isSubmitting }) => (
            <Form className='viewEditForm'>
              {isSubmitting && <LinearProgress />}

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="text"
                    label="Email"
                    name="email"
                  />
                </Grid>
                <Grid item xs={12} className="buttons-wrap forgot-wrap">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      </Popup>
      <Grid container component="main" className="login-wrap">

        <Notification notify={notify} setNotify={setNotify} />
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img src="/imgs/bevootech.svg" alt="Profile Pic" className="logo lg" />
            <Typography component="h5" variant="h5">
              Sign in
            </Typography>
            <Formik
              initialValues={initVal}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  let pinExpiry = format(new Date(new Date().getTime() + (60 * 3000)), 'yyyy-MM-dd HH:mm:ss');
                  //login
                  axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/login`, {
                    username: values.username,
                    password: values.password,
                    pinExpiry: pinExpiry,
                  })
                    .then(res => {
                      console.log(res);
                      //set credential localstorage
                      if (res.data === "INACTIVE_ACCOUNT") {
                        setSubmitting(false);
                        setNotify({
                          isOpen: true,
                          message: "Your account is inactive you can't login!",
                          type: "error",
                        });
                      }
                      else if (res.data != "Invalid Credential") {
                        //set user state
                        setUser_id(res.data[0].user_id);
                        setUsername(res.data[0].username);
                        setUser_level(res.data[0].user_level);
                        setToken(res.data[0].token);
                        setPinEmail(res.data[0].email);

                        setSubmitting(false);
                        setPinPopup(true);
                        console.log(res.data);
                      }
                      else {
                        setSubmitting(false);
                        setNotify({
                          isOpen: true,
                          message: "Invalid Username/Password.",
                          type: "error",
                        });
                      }
                    })
                    .catch(error => {
                      alert(JSON.stringify(error))
                      setSubmitting(false);
                      setNotify({
                        isOpen: true,
                        message: "Invalid Username/Password.",
                        type: "error",
                      });
                      console.log(error)
                    });
                }, 500);
              }}
              //validation
              validate={values => {
                const errors: Partial<Values> = {};
                if (!values.username) {
                  errors.username = 'Required';
                }
                if (!values.password) {
                  errors.password = 'Required';
                }

                return errors;
              }}
            >

              {({ submitForm, isSubmitting }) => (
                <Form>
                  {isSubmitting && <LinearProgress />}
                  <Field
                    variant="outlined"
                    component={TextField}
                    type="text"
                    label="Username"
                    name="username"
                    fullWidth="true"
                    autoFocus
                  />
                  <Field
                    variant="outlined"
                    component={TextField}
                    type="password"
                    label="Password"
                    name="password"
                    fullWidth="true"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    fullWidth="true"
                  >
                    Login
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <span onClick={() => forgotPopupShow()} className="forgot-pwd">
                        Forgot password?
                      </span>
                    </Grid>
                  </Grid>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}