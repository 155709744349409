import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText} from '@material-ui/core';
import axios from "axios";

export default function IdleTimerContainer(){
	const [open, setOpen] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(true);

	const handleOnIdle = event => {
	    console.log('user is idle');
	    setOpen(true);
	}

	const handleOnActive = event => {
	    console.log('user is active');
	}

	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
	    timeout: 300 * 1000,
	    onIdle: handleOnIdle,
	    onActive: handleOnActive,
	    debounce: 500
	})

	//popup
	const handleClose = () => {
		setOpen(false);
	};

	//logout
	 const handleLogout = () => {
	 	setOpen(false);
	    let token = localStorage.getItem("token");

	    //authentication
	    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, { 
	      auth: token,
	    })
	    .then(auth => {
	      if(auth.data == true){
	        axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/logout`, { 
	          token: token,
	        })
	        .then(res => {
	          if(res.data == true){
	            localStorage.clear();
	            window.location.replace("/");
	          }
	        })
	        .catch(error => {
	          console.log(error)
	        });      
	      }
	      else{
	        localStorage.clear();
	        window.location.replace("/");
	      }
	    })
	    .catch(error => {
	     console.log(error)
	    });
	};


	return(
		<div>
			<Dialog
		        open={open}
		        onClose={handleClose}
		        aria-labelledby="alert-dialog-title"
		        aria-describedby="alert-dialog-description"
		      >
		        <DialogTitle id="alert-dialog-title">{"You've been idle for a while!"}</DialogTitle>
		        <DialogContent>
		          <DialogContentText id="alert-dialog-description">
		            You will be logged out soon.
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions>
		          <Button onClick={handleLogout} color="primary">
		            Log me out
		          </Button>
		          <Button onClick={handleClose} color="primary" autoFocus>
		            Keep me signed in
		          </Button>
		        </DialogActions>
		      </Dialog>
		</div>
	) 
}
