import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from "formik";
import { TextField, CheckboxWithLabel, Select } from 'formik-material-ui';
import axios from "axios";
import {
  Button,
  LinearProgress,
  Grid,
  makeStyles,
  DialogActions,
  MenuItem
} from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import PageHeader from '../components/PageHeader';
import Popup from "../components/Popup";
import Notification from "../components/Notification";
import { format } from "date-fns";

//styles
const useStyles = makeStyles({
  root: {
    "& .MuiGrid-item": {
      padding: "24px 24px 0"
    },
    "& .MuiGrid-item:last-child": {
      paddingBottom: "24px"
    }
  },
  popClose: {
    padding: "0",
    minWidth: "0"
  }
})

export default function Enquiry() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSalesperson, setIsSalesperson] = useState(false);
  const classes = useStyles();
  const [rangesSalesperson, setRangesSalesperson] = useState([]);
  const [rangesPartner, setRangesPartner] = useState([]);
  const [openTNCPopup, setOpenTNCPopup] = useState(false);
  const [openAddEnquiryPopup, setOpenAddEnquiryPopup] = useState(false);
  const [addingNewEnquiry, setAddingNewEnquiry] = useState(false)
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const initialValues = {
    company_name: "",
    contact_name: "",
    contact_email: "",
    contact_mobile: "",
    contact_address: "",
    contact_office_number: "",
    comments: "",
    terms: "",
  };

  //close tnc popup
  const closeTNCPopup = () => {
    setOpenTNCPopup(false);
  };

  //close add popup
  const closeAddEnquiryPopup = () => {
    setOpenAddEnquiryPopup(false);
    window.location.replace("https://partnerportal.bevootech.com/#/home");
  };

  const pageTokenCheck = () => {
    let token = localStorage.getItem("token");
    if (!token) {
      document.body.classList.add("login-page");
      window.location.replace("https://partnerportal.bevootech.com/#/");
    }
  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user_level = localStorage.getItem("user_level");
    pageTokenCheck();

    //check user level
    if (user_level == "Admin") {
      setIsAdmin(true);
    }
    if (user_level == "Salesperson") {
      setIsSalesperson(true);
    }

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //fetch list of salesperson
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/salespersons`).then((resSalesperson) => {
            setRangesSalesperson(resSalesperson.data);
          });

          //fetch list of partner
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/partners`).then((resPartner) => {
            setRangesPartner(resPartner.data);
          });

        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }, []);

  return (
    <div className="container">
      <Notification notify={notify} setNotify={setNotify} />
      <PageHeader
        title="Create New Enquiry"
        subTitle="Enquiry will be created and notified to sales "
        icon={<CreateIcon />}
      />
      <div className="sec-wrap">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            //tnc validation
            if (!values.terms) {
              setOpenTNCPopup(true);
              setSubmitting(false);
            }
            else {
              setTimeout(() => {
                let token = localStorage.getItem("token");

                setSubmitting(true)
                setAddingNewEnquiry(true)

                //authentication
                axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                  auth: token,
                })
                  .then(auth => {
                    if (auth.data == true) {
                      const currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
                      const user_id = localStorage.getItem("user_id");
                      const admin_id = localStorage.getItem("admin_id");
                      const username = localStorage.getItem("username");
                      const name = localStorage.getItem("name");

                      if (isAdmin) {
                        if (values.partner == null) {
                          values.partner = user_id;
                        }
                      }

                      let partner_id;
                      if (isAdmin) {
                        partner_id = values.partner || user_id;
                      } else if (isSalesperson) {
                        partner_id = values.partner || null;
                      } else {
                        partner_id = user_id;
                      }

                      axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry/add`, {
                        user_id: partner_id,
                        datetime_of_creation: currentDate,
                        company_name: values.company_name_input,
                        contact_name: values.contact_name,
                        contact_email: values.contact_email,
                        mobile: values.contact_mobile,
                        address: values.contact_address,
                        office_number: values.contact_office_number,
                        comments: values.comments,
                        status: "Enquiry",
                        closed_date: "",
                        sales_amount: "",
                        commission_amount: "",
                        // sales_person: (isAdmin) ? values.sales_person : "",
                        salesperson_id: isAdmin ? values.salesperson_id : isSalesperson ? user_id : null,
                        admin_id: isAdmin ? "" : admin_id,
                        username: username,
                        name: name
                      })
                        .then(res => {
                          if (res.data) {
                            setAddingNewEnquiry(false)
                            setSubmitting(false);
                            setOpenAddEnquiryPopup(true);
                          }
                          else {
                            setAddingNewEnquiry(false)
                            setSubmitting(false);
                            setNotify({
                              isOpen: true,
                              message: `Invalid Request.`,
                              type: "error",
                            });
                          }

                        })
                        .catch(error => {
                          setAddingNewEnquiry(false)
                          setSubmitting(false);
                          setNotify({
                            isOpen: true,
                            message: `Invalid Request.`,
                            type: "error",
                          });
                          console.log(error);
                        });

                    }
                    else {
                      localStorage.clear();
                      window.location.replace("https://partnerportal.bevootech.com/#/home");
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  });
              }, 500);
            }
          }}
          //validation
          validate={values => {
            const errors: Partial<Values> = {};
            if (!values.company_name_input) {
              errors.company_name_input = 'Required';
            }

            if (!values.contact_name) {
              errors.contact_name = 'Required';
            }

            // if (!values.contact_email) {
            //   errors.contact_email = 'Required';
            // }
            // else if (
            //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)
            // ) {
            //   errors.contact_email = 'Invalid email address';
            // }

            // if (!values.contact_mobile) {
            //   errors.contact_mobile = 'Required';
            // }

            // if (!values.contact_address) {
            //   errors.contact_address = 'Required';
            // }

            // if (!values.contact_office_number) {
            //   errors.contact_office_number = 'Required';
            // }

            if (!values.comments) {
              errors.comments = 'Required';
            }

            return errors;
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form className={classes.root}>
              <Popup
                openPopup={openTNCPopup}
                setOpenPopup={setOpenTNCPopup}
                title={
                  <div style={{ textAlign: "right" }}>
                    <Button
                      onClick={() => {
                        closeTNCPopup();
                      }}
                      className={classes.popClose}
                    >
                      x
                    </Button>
                  </div>
                }
              >
                <p>Please accept the Terms and Conditions.</p>
              </Popup>
              <Popup
                openPopup={openAddEnquiryPopup}
                setOpenPopup={setOpenAddEnquiryPopup}
                title="Enquiry Submmission"
              >
                <p>Your enquiry has been submitted successfully.</p>
                <DialogActions>
                  <Button onClick={closeAddEnquiryPopup} color="primary">
                    Okay
                  </Button>
                </DialogActions>
              </Popup>
              {addingNewEnquiry && <LinearProgress />}
              <div className="enquiry-tbl-wrap">
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      type="text"
                      label="Company Name"
                      name="company_name_input"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      type="text"
                      label="Contact Name"
                      name="contact_name"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      type="text"
                      label="Contact Email"
                      name="contact_email"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      type="text"
                      label="Mobile"
                      name="contact_mobile"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      type="textarea"
                      label="Address"
                      name="contact_address"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      type="textarea"
                      label="Office Number"
                      name="contact_office_number"
                    />
                  </Grid>
                  {isAdmin &&
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        type="text"
                        select
                        name="salesperson_id"
                        label="Salesperson"
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {rangesSalesperson.map((option) => (
                          <MenuItem key={option.user_id} value={option.user_id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  }
                  {isAdmin || isSalesperson &&
                    <Grid item xs={6}>
                      <Field
                        component={TextField}
                        type="text"
                        select
                        name="partner"
                        label="Partner"
                        variant="standard"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      >
                        {rangesPartner.map((option) => (
                          <MenuItem key={option.user_id} value={option.user_id}>
                            {option.username}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      type="textarea"
                      label="Comments"
                      name="comments"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      type="checkbox"
                      component={CheckboxWithLabel}
                      name="terms"
                      Label={{ label: 'By submitting these details, you confirm that consent has been obtained from the customer to allow our representative to contact them.' }}
                    />
                  </Grid>
                  <Grid item xs={12} className="txt-right">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
