import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import DescriptionIcon from '@material-ui/icons/Description';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import PostAddIcon from '@material-ui/icons/PostAdd';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import { TextField as TextFieldFormik, Select } from 'formik-material-ui';
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  Toolbar,
  TextField,
  LinearProgress,
  Grid,
  Typography,
  MenuItem

} from '@material-ui/core';

//components
import DocumentUserTbl from '../components/DocumentUserTbl';
import AllRecordsTbl from '../components/AllRecordsTbl';
import ConfirmDialog from "../components/ConfirmDialog";
import Popup from '../components/Popup';
import Notification from "../components/Notification";

//table document headcells - Admin
const headCells = [
  { id: 'title', label: 'Title' },
  { id: 'description', label: 'Description' },
  { id: 'ul', label: 'URL' },
  { id: 'document_id', label: 'Document ID' },
  { id: 'details', label: 'Details' }
]

//table document headcells - User
const headCellsUser = [
  { id: 'title', label: 'Title' },
  { id: 'description', label: 'Description' },
  { id: 'ul', label: 'URL' }
]

export default function Documents() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [records, setRecords] = useState([]);
  const [recordsUser, setRecordsUser] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [filterFnUser, setFilterFnnUser] = useState({ fn: items => { return items; } });
  const [showFilter, setShowFilter] = useState(false); const {
    TblContainer,
    TblPagination,
    TblHead,
    recordsAfterPagingAndSorting
  } = AllRecordsTbl(records, headCells, filterFn);
  const [showFilterUser, setShowFilterUser] = useState(false); const {
    TblContainerUser,
    TblPaginationUser,
    TblHeadUser,
    recordsAfterPagingAndSortingUser
  } = DocumentUserTbl(recordsUser, headCellsUser, filterFnUser);
  const [showPagination, setShowPagination] = useState(false);
  const [showPaginationUser, setShowPaginationUser] = useState(false);
  const [viewEditPopup, setViewEditPopup] = useState(false);
  const [addDocPopup, setAddDocPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    isOpen: false,
    title: "",
  });
  const [deleteNotify, setDeleteNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [addNotify, setAddNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [updateNotify, setUpdateNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [showLinear, setShowLinear] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const [partner, setPartner] = useState([]);
  const [curSharedPartner, setCurSharedPartner] = useState([]);
  const [currDocId, setCurrDocId] = useState('');

  //dropdown options
  const [partnerList, setPartnerList] = useState([]);

  //partner onSelect
  const onSelect = (data) => {
    //filter user_ids
    let user_id = data.map((item) => item.user_id);
    setPartner(user_id);
  }

  //partner onRemove
  const onRemove = (data) => {
    //filter user_ids
    let user_id = data.map((item) => item.user_id);
    setPartner(user_id);
  }

  //add document
  function documentAddSubmit(event) {
    event.preventDefault();

    //validation
    if (title == "") {
      alert("Title field is required");
    }
    else if (url == "") {
      alert("URL field is required");
    }
    else {
      setShowLinear(true);
      axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents/add`, {
        document_title: title,
        document_description: description,
        document_url: url,
        userids_array: partner,
      })
        .then(res => {
          if (res.data == 1) {
            setUrl('');
            setDescription('');
            setTitle('');
            setPartner([]);
            //fetch list of documents
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents`).then((res) => {
              const responseRecords = res.data;
              setRecords(responseRecords);

              let countE = Object.keys(responseRecords).length;
              if (countE > 0) {
                setShowPagination(false);
              }
              else {
                setShowPagination(true);
              }
            });
            setShowLinear(false);
            setAddDocPopup(false);
            setAddNotify({
              isOpen: true,
              message: `${title} has been successfully added.`,
              type: "success",
            });
          }
          else {
            console.log(res.data);
            setAddDocPopup(false);
            setAddNotify({
              isOpen: true,
              message: `Invalid Request`,
              type: "error",
            });
          }

        })
        .catch(error => {
          setAddDocPopup(false);
          setAddNotify({
            isOpen: true,
            message: `Invalid Request.`,
            type: "error",
          });
          console.log(error)
        });
    }

  }
  //update document
  function documentUpdateSubmit(event) {
    event.preventDefault();

    //validation
    if (title == "") {
      alert("Title field is required");
    }
    else if (url == "") {
      alert("URL field is required");
    }
    else {
      /*
      console.log(title);
      console.log(description);
      console.log(url);
      console.log(partner);
      console.log(currDocId);
      */

      setShowLinear(true);
      axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents/update/${currDocId}`, {
        document_title: title,
        document_description: description,
        document_url: url,
        userids_array: partner,
      })
        .then(res => {
          if (res.data == 1) {
            //clear values
            setUrl('');
            setDescription('');
            setTitle('');
            setPartner([]);
            setCurrDocId('');

            //fetch list of documents
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents`).then((res) => {
              const responseRecords = res.data;
              setRecords(responseRecords);

              let countE = Object.keys(responseRecords).length;
              if (countE > 0) {
                setShowPagination(false);
              }
              else {
                setShowPagination(true);
              }
            });
            setShowLinear(false);
            setViewEditPopup(false);
            setUpdateNotify({
              isOpen: true,
              message: `${title} has been successfully modified.`,
              type: "success",
            });
          }
          else {
            console.log(res.data);
            setShowLinear(false);
            setViewEditPopup(false);
            setUpdateNotify({
              isOpen: true,
              message: `Invalid Request`,
              type: "error",
            });
          }

        })
        .catch(error => {
          setShowLinear(false);
          setViewEditPopup(false);
          setUpdateNotify({
            isOpen: true,
            message: `Invalid Request.`,
            type: "error",
          });
          console.log(error)
        });
    }

  }

  //search admin document
  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.title.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //search user document
  const handleSearchUser = e => {
    let target = e.target;
    setFilterFnnUser({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.title.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //form edit initial values
  const [editInitVal, setEditInitVal] = useState({
    title: "",
    description: "",
    url: "",
    partner: ""
  });


  const pageTokenCheck = () => {
    let token = localStorage.getItem("token");
    if (!token) {
      document.body.classList.add("login-page");
      window.location.replace("https://partnerportal.bevootech.com/#/");
    }
  }

  //show view edit popup
  const showDetailsPopup = (row) => {
    setUrl(row.url);
    setDescription(row.description);
    setTitle(row.title);
    setCurrDocId(row.id);
    //fetch shared partner
    axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents/getuser/${row.id}`).then((res) => {
      const sharedPartner = res.data;
      const user_id_arr = sharedPartner.map((item) => item.user_id);

      //set current shared partner to dropdown
      setCurSharedPartner(sharedPartner);
      setPartner(user_id_arr);

    });

    setViewEditPopup(true);
  };

  //close view edit popup
  const closeViewEditPopup = () => {
    setViewEditPopup(false);
  };

  //add salesperson
  const addDoc = () => {
    //clear fields 
    setUrl('');
    setDescription('');
    setTitle('');
    setPartner([]);
    setAddDocPopup(true);
  }

  //close add popup
  const closeDoc = () => {
    //clear fields 
    setUrl('');
    setDescription('');
    setTitle('');
    setPartner([]);

    setAddDocPopup(false);
  };


  //confirm delete enquiry
  const confirmDelDoc = (row) => {

    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {

          //delete document based on id
          axios.delete(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents/delete/${row.id}`).then((resDelete) => {
            if (resDelete.data === 1) {

              setIsAdmin(true);

              //fetch list of documents
              axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents`).then((res) => {
                const responseRecords = res.data;
                setRecords(responseRecords);

                let countE = Object.keys(responseRecords).length;
                if (countE > 0) {
                  setShowPagination(false);
                }
                else {
                  setShowPagination(true);
                }
              });

              setConfirmDelete({
                ...confirmDelete,
                isOpen: false,
              });
              setDeleteNotify({
                isOpen: true,
                message: `Document has been successfully deleted.`,
                type: "success",
              });
            }
            else {
              setConfirmDelete({
                ...confirmDelete,
                isOpen: false,
              });
              setDeleteNotify({
                isOpen: true,
                message: `Unable to process your request`,
                type: "error",
              });
            }
          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });
  };

  useEffect(() => {
    pageTokenCheck();

    let token = localStorage.getItem("token");
    let user_level = localStorage.getItem("user_level");
    let user_id = localStorage.getItem("user_id");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //check user level
          if (user_level == "Admin") {
            setIsAdmin(true);

            //fetch list of documents
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents`).then((res) => {
              const responseRecords = res.data;
              setRecords(responseRecords);

              let countE = Object.keys(responseRecords).length;
              if (countE > 0) {
                setShowPagination(false);
              }
              else {
                setShowPagination(true);
              }
            });

            //fetch list of partner and insert to dropdown
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users`).then((resPartner) => {
              var filteredPartner = resPartner.data.filter(item => item.user_level == "Partner");
              setPartnerList(filteredPartner);
            });

          }
          else {
            //fetch list of documents
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/documents/${user_id}`).then((resUser) => {
              const responseRecordsUser = resUser.data;
              setRecordsUser(responseRecordsUser);

              let count = Object.keys(responseRecordsUser).length;
              if (count > 0) {
                setShowPaginationUser(false);
              }
              else {
                setShowPaginationUser(true);
              }
            });
          }

        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });
  }, []);

  return (
    <div className="container">
      <Notification notify={deleteNotify} setNotify={setDeleteNotify} />
      <Notification notify={addNotify} setNotify={setAddNotify} />
      <Notification notify={updateNotify} setNotify={setUpdateNotify} />
      <ConfirmDialog
        confirmDialog={confirmDelete}
        setConfirmDialog={setConfirmDelete}
      />
      <Popup
        openPopup={addDocPopup}
        setOpenPopup={setAddDocPopup}
        title={
          <div className="popup-title-bar">
            ADD DOCUMENT
            <Button
              onClick={() => {
                closeDoc();
              }}
              className="pop-close"
            >
              x
            </Button>
          </div>
        }
      >
        {showLinear && <LinearProgress />}
        <form className='viewEditForm addDoc' onSubmit={documentAddSubmit}>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Title"
                name="title"
                value={title}
                onInput={e => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="URL"
                name="url"
                value={url}
                onInput={e => setUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className="multi-select">
              <Multiselect
                options={partnerList}
                displayValue="name"
                onSelect={onSelect}
                onRemove={onRemove}
                placeholder="Share to"
                closeIcon="circle"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={description}
                onInput={e => setDescription(e.target.value)}
                rows={3}
                multiline={true}
              />
            </Grid>
            <Grid item xs={12} className="buttons-wrap">
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </form>

      </Popup>
      <Popup
        openPopup={viewEditPopup}
        setOpenPopup={setViewEditPopup}
        title={
          <div className="popup-title-bar">
            INFORMATION
            <Button
              onClick={() => {
                closeViewEditPopup();
              }}
              className="pop-close"
            >
              x
            </Button>
          </div>
        }
      >
        {showLinear && <LinearProgress />}
        <form className='viewEditForm editDoc' onSubmit={documentUpdateSubmit}>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Title"
                name="title"
                value={title}
                onInput={e => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="URL"
                name="url"
                value={url}
                onInput={e => setUrl(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className="multi-select">
              <Multiselect
                options={partnerList}
                displayValue="name"
                onSelect={onSelect}
                onRemove={onRemove}
                placeholder="Share to"
                closeIcon="circle"
                selectedValues={curSharedPartner}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={description}
                onInput={e => setDescription(e.target.value)}
                rows={3}
                multiline={true}
              />
            </Grid>
            <Grid item xs={12} className="buttons-wrap">
              <Button
                variant="contained"
                color="primary"
                type="submit"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </Popup>
      <PageHeader
        title="Documents"
        subTitle="Share product/service document links with partners"
        icon={<DescriptionIcon />}
      />
      <div className="sec-wrap search-field">
        {isAdmin &&
          <div className="search-filter-wrap">
            <Toolbar>
              <TextField
                label="Search Title"
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                }}
                onChange={handleSearch}
              />
            </Toolbar>
            <Button variant="contained" color="primary" onClick={() => addDoc()} className="button-icon">
              <PostAddIcon /> Add Doc
            </Button>
          </div>
        }
        {!isAdmin &&
          <div className="search-filter-wrap">
            <Toolbar>
              <TextField
                label="Search Title"
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                }}
                onChange={handleSearchUser}
              />
            </Toolbar>
          </div>
        }
        {isAdmin &&
          <div className="tbl-minpad">
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell>
                      {row.description}
                    </TableCell>
                    <TableCell>
                      {/* <a href={row.url} target="_blank" rel="noopener noreferrer">{row.url}</a> */}
                      <div className='flex-center'>
                        <p>{row.url}</p>
                        <button
                          className='link-button'
                          onClick={() => {
                            let modifiedUrl = row.url;
                            if (!modifiedUrl.startsWith('http://') && !modifiedUrl.startsWith('https://')) {
                              // If the URL doesn't start with 'http://' or 'https://', assume it's missing the protocol.
                              modifiedUrl = 'https://' + modifiedUrl;
                            }
                            window.open(modifiedUrl, '_blank');
                          }}
                        >
                          <OpenInNewIcon />
                        </button>
                      </div>
                    </TableCell>
                    <TableCell>
                      {row.id}
                    </TableCell>
                    <TableCell>
                      <a className="view-details" onClick={() => showDetailsPopup(row)}><VisibilityIcon /></a>
                      <a className="delete-enquiry" onClick={() => {
                        setConfirmDelete({
                          isOpen: true,
                          title:
                            "Are you sure you want to delete this document?",
                          onConfirm: () => {
                            confirmDelDoc(row);
                          },
                        });
                      }}><DeleteIcon /></a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
          </div>
        }
        {isAdmin &&
          <div>
            {(showPagination == false) ? <TblPagination /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
          </div>
        }
        {!isAdmin &&
          <div className="tbl-minpad">
            <TblContainerUser>
              <TblHeadUser />
              <TableBody>
                {recordsAfterPagingAndSortingUser().map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell>
                      {row.description}
                    </TableCell>
                    <TableCell>
                      {row.url}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainerUser>
          </div>
        }
        {!isAdmin &&
          <div>
            {(showPaginationUser == false) ? <TblPaginationUser /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
          </div>
        }
      </div>
    </div>
  );

}
