import React, {useState} from 'react';
import {Table, TableHead, TableRow, TableCell, TablePagination, TableSortLabel} from '@material-ui/core';

export default function AppointmentTbl(records, headCells, filterFn){

	//pagination
	const pages = [25,50,100,200];
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(pages[page])
	const [order,setOrder] = useState();
	const [orderBy, setOrderBy] = useState();

	const TblContainerAppointment = props =>(
		<Table>
			{props.children}
		</Table>
	)

	const TblHeadAppointment = props =>{

		const handleSortRequest = cellId =>{
			const isAsc = orderBy === cellId && order === 'asc';
			setOrder(isAsc?'desc':'asc')
			setOrderBy(cellId);
		} 

		return(<TableHead>
			<TableRow>{
					headCells.map(headCell =>(<TableCell key={headCell.id}>
						{ 
							headCell.disableSorting?headCell.label:
							<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id?order:'asc'} onClick = {()  =>{handleSortRequest(headCell.id)}}>
							{headCell.label}
							</TableSortLabel>
						}
					</TableCell>))
			}</TableRow>
		</TableHead>)
	}

	const handleChangePage = (event, newPage) =>{
		setPage(newPage)
	}

	const handleChangeRowsPerPage = event =>{
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const TblPaginationAppointment = () =>(<TablePagination 	
		component = 'div'
		page = {page}
		rowsPerPageOptions = {pages}
		rowsPerPage = {rowsPerPage}
		count = {records.length}
		onChangePage = {handleChangePage}
		onChangeRowsPerPage = {handleChangeRowsPerPage}
	/>)

	function stableSort(array, comparator){
		const stabilizedThis = array.map((el,index) => [el,index]);
		stabilizedThis.sort((a,b) => {
			const order = comparator(a[0], b[0]);
			if(order !== 0){
				return order;
			}
			return a[1] - b[1];
		})
		return stabilizedThis.map((el) => el[0]);
	}

	function getComparator(order, orderBy){
		return order === 'desc'
			? (a, b) => descendingComparator(a,b, orderBy)
			: (a, b) => -descendingComparator(a,b, orderBy);
	}

	function descendingComparator(a,b,orderBy){
		if(b[orderBy] < a[orderBy]){
			return -1;
		}
		if(b[orderBy] > a[orderBy]){
			return 1;
		}
		return 0;
	}

	const paginSortingAppointment = () => {
		return stableSort(filterFn.fn(records),getComparator(order,orderBy)).slice(page*rowsPerPage, (page+1)*rowsPerPage)
	}  

	return{
		TblContainerAppointment,
		TblHeadAppointment,
		TblPaginationAppointment,
		paginSortingAppointment
	}
}