import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function Notification(props) {
	const { notify, setNotify } = props;

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setNotify({
			...notify,
			isOpen: false
		})
	}

	return (
		<Snackbar
			open={notify.isOpen}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			onClose={handleClose}
			autoHideDuration={3000}
		>
			<Alert severity={notify.type} onClose={handleClose}>
				{notify.message}
			</Alert>
		</Snackbar>
	);
}