import React, { useState, useEffect } from "react";
import { 
  makeStyles,
  useTheme,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  IconButton,
  Button,
  ListItemText,
  ListItemIcon,
  ListItem,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Grid
} from '@material-ui/core';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import axios from "axios";
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import { format } from "date-fns";
import {TextField as TextFieldFormik} from 'formik-material-ui';

//components
import ConfirmDialog from "./ConfirmDialog";
import Popup from '../components/Popup';
import Notification from "./Notification";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  defaultLink:{
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: '2'
  }
}));

export default function Nav(){
  const classes = useStyles();
  //form initial values
  const [initVal, setInitVal] = useState({
    username: "",
    email: "",
    name: "",
    company_name: "",
    mobile: "",
    address: "",
    password: "",
  });
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);

  //toggle profile section
  const toggleProfile = () => {
    setProfileSec(!profileSec);
  };

  const [profileSec, setProfileSec] = useState(false);
  const [profilePopup, setProfilePopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  //logout
  const handleLogout = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, { 
      auth: token,
    })
    .then(auth => {
      if(auth.data == true){
        axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/logout`, { 
          token: token,
        })
        .then(res => {
          if(res.data == true){
            localStorage.clear();
            window.location.replace("/");
          }
        })
        .catch(error => {
          console.log(error)
        });      
      }
      else{
        localStorage.clear();
        window.location.replace("/");
      }
    })
    .catch(error => {
     console.log(error)
    });
    
  };

  const ProfileData = () => {
    return(
      <Card className="profile-wrap" elevation={3}>
                <CardContent>
                    <div className="header-content">
                      <img src="/imgs/omar-lg.jpg" alt="Profile Pic" />
                      <div className="info">
                        <Typography variant="h6">
                          {localStorage.getItem("name")}
                        </Typography>
                        <Typography variant="subtitle2">
                          {localStorage.getItem("email")}
                        </Typography>
                      </div>
                    </div>
                    <div className="body-content">
                      <Typography variant="subtitle2" className="label">
                          Company Name
                      </Typography>
                      <Typography variant="subtitle2" className="mgbt-10">
                          {localStorage.getItem("company_name")}
                      </Typography>
                      <Typography variant="subtitle2" className="label">
                          Phone Number
                      </Typography>
                      <Typography variant="subtitle2" className="mgbt-10">
                          {localStorage.getItem("mobile")}
                      </Typography>
                      <Typography variant="subtitle2" className="label">
                          Address
                      </Typography>
                      <Typography variant="subtitle2" className="mgbt-10">
                        {localStorage.getItem("address")}
                      </Typography>
                    </div>
                </CardContent>
                <CardActions>
                  <div>
                    <Button size="small" color="primary" variant="contained" onClick={() => { openProfilePopup(); }}>Update</Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title:
                              "Are you sure you want to logout?",
                            onConfirm: () => {
                              handleLogout();
                            },
                          });
                        }}
                      >
                        Logout
                      </Button>
                  </div>
                </CardActions>
              </Card>
    )
  }

  //open profile popup
  const openProfilePopup = () => {
    setProfileSec(false);
    setProfilePopup(true);

    let user_id =localStorage.getItem("user_id");
    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, { 
      auth: token,
    })
    .then(auth => {
      if(auth.data == true){
        axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/${user_id}`).then((res) => {
      
          //variables
          let username = res.data[0].username;
          let email = res.data[0].email;
          let name = res.data[0].name;
          let company_name = res.data[0].company_name;
          let mobile = res.data[0].mobile;
          let address = res.data[0].address;
          let password = res.data[0].password;

          //fetch db data
          setInitVal({
            username: username,
            email: email,
            name: name,
            company_name: company_name,
            mobile: mobile,
            address: address,
            password: password,
          });

        });      
      }
      else{
        localStorage.clear();
        window.location.replace("/");
      }
    })
    .catch(error => {
     console.log(error)
    });

  };

  //close view edit popup
  const closeProfilePopup = () => {
    setProfilePopup(false);
  };

  //menu items
  const menuItems = [
    {text: "Dashboard", icon: <HomeIcon />, path: "/home"},
    {text: "Enquiry", icon: <CreateIcon />, path: "/enquiry"},
    {text: "My Commission", icon: <MonetizationOnIcon />, path: "/my-commission"}

  ]

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("user_id");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, { 
      auth: token,
    })
    .then(auth => {
      if(auth.data == true){
        axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/${user_id}`).then((res) => {

          //use data
          localStorage.setItem("name", res.data[0].name);
          localStorage.setItem("email", res.data[0].email);
          localStorage.setItem("company_name", res.data[0].company_name);
          localStorage.setItem("mobile", res.data[0].mobile);
          localStorage.setItem("address", res.data[0].address);
          
        });     
      }
    })
    .catch(error => {
     console.log(error)
    });

  }, []);

  return(
    <div>
    <Notification notify={notify} setNotify={setNotify} />
    <ConfirmDialog
      confirmDialog={confirmDialog}
      setConfirmDialog={setConfirmDialog}
    />
    <Popup
      openPopup={profilePopup}
      setOpenPopup={setProfilePopup}
      title={
        <div className="popup-title-bar">
          PROFILE INFORMATION
          <Button
            onClick={() => {
              closeProfilePopup();
            }}
            className="pop-close"
          >
            x
          </Button>
        </div>
      }
    >
      
    <Formik
              initialValues={initVal}
              enableReinitialize
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {

                  let token = localStorage.getItem("token");
                  let user_id = localStorage.getItem("user_id");

                  //authentication
                  axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, { 
                    auth: token,
                  })
                  .then(auth => {
                    
                    const newDate = new Date();
                    const modificate_date = format(newDate, 'yyyy-MM-dd HH:mm:ss');

                    axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/update/${user_id}`, {
                      username:                          values.username,
                      datetime_of_modification:          modificate_date,
                      email:                             values.email,
                      name:                              values.name,
                      company_name:                      values.company_name,
                      mobile:                            values.mobile,
                      address:                           values.address,
                      password:                          values.password,
                    })
                    .then(function (response) {
                      setProfilePopup(false);

                      if(response.data == true){

                        localStorage.setItem("name", values.name);
                        localStorage.setItem("email", values.email);
                        localStorage.setItem("company_name", values.company_name);
                        localStorage.setItem("mobile", values.mobile);
                        localStorage.setItem("address", values.address);

                        setNotify({
                          isOpen: true,
                          message: `${values.username} has been successfully updated.`,
                          type: "success",
                        });
                      }
                      else{
                        setNotify({
                          isOpen: true,
                          message: `Invalid Request.`,
                          type: "error",
                        });
                      }

                    });
                  })
                  .catch(error => {
                   console.log(error)
                  });
                  

                  closeProfilePopup(false);  
                  setSubmitting(false);

                }, 500);
              }}
              //validation
              validate={values => {
                const errors: Partial<Values> = {};

                if (!values.username) {
                  errors.username = 'Required';
                }
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                if (!values.name) {
                  errors.name = 'Required';
                }
                if (!values.company_name) {
                  errors.company_name = 'Required';
                }
                if (!values.mobile) {
                  errors.mobile = 'Required';
                }
                if (!values.address) {
                  errors.address = 'Required';
                }
                if (!values.password) {
                  errors.password = 'Required';
                }

                return errors;
              }}
            >

            {({ submitForm, isSubmitting }) => (
              <Form>
               {isSubmitting && <LinearProgress />}

                  <Grid container spacing={6} className="mgbt-0">
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Username"
                        name="username"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Email"
                        name="email"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Name"
                        name="name"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Company Name"
                        name="company_name"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Phone Number"
                        name="mobile"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Address"
                        name="address"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="password"
                        name="password"
                      />
                    </Grid>
                  </Grid>

                  <div className="buttons-wrap">
                  <Button
                      variant="contained"
                      onClick={() => closeProfilePopup(false) }
                      className="mgr-10"
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                  </div>

                </Form>
              )}
            </Formik>

    </Popup>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <div className={classes.logoWrap}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon className="burger-icon" />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <img src="/imgs/bevootech.svg" alt="Profile Pic" className="logo" />
            </Typography>
          </div>
          
          <div className="account-wrap">
            <img src="/imgs/omar.jpg" alt="Profile Pic" />
            <div className="sub-links-wrap">
              <IconButton onClick={() => { toggleProfile(); }} >
                <ExpandMoreIcon />
              </IconButton>
              {profileSec && <ProfileData />}
              
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <List>
          {menuItems.map((item) => {
          const {text, icon, path} = item;
          return(
            <Link to={path} className={classes.defaultLink}>
              <ListItem button key={text}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={text} />
                </ListItem> 
           </Link>
          );
          })}
        </List>
      </Drawer>
    </div>
  ); 
}


