import React from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle
}	from '@material-ui/core';

export default function Popup(props){
	const {title, children, openPopup} = props;

	return(
		<Dialog open={openPopup} maxWidth='md'>
			<DialogTitle children={title}>
			</DialogTitle>
			<DialogContent dividers>
				{children}
			</DialogContent>
		</Dialog>
	)
}