import React, { useEffect, useState } from 'react';
import axios from "axios";
import {
  Paper,
  Card,
  Typography,
  makeStyles,
  Tabs,
  Tab,
  Box,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Toolbar,
  TextField,
  LinearProgress,
  Grid,
  MenuItem,
  Input,
} from "@material-ui/core"
import { Formik, Form, Field } from "formik";
import { TextField as TextFieldFormik, Select } from 'formik-material-ui';
import {
  DatePicker,
  KeyboardDatePicker
} from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
//icons
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import NumberFormat from 'react-number-format';

//components
import PageHeader from '../components/PageHeader';
import Popup from '../components/Popup';
import AllRecordsTbl from '../components/AllRecordsTbl';
import AppointmentTbl from '../components/AppointmentTbl';
import FollowUpTbl from '../components/FollowUpTbl';
import OnHoldTbl from '../components/OnHoldTbl';
import ClosedTbl from '../components/ClosedTbl';
import LostTbl from '../components/LostTbl';
import PaymentDetailsTbl from '../components/PaymentDetailsTbl';
import CommissionPaymentDetailsTbl from '../components/CommissionPaymentDetailsTbl';
import EnquiryActivitiesTbl from '../components/EnquiryActivitiesTbl';
import Notification from "../components/Notification";
import ConfirmDialog from "../components/ConfirmDialog";

//datetime pacakges
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range';
import { format } from "date-fns";

//payment details table head cells
const headCellsPD = [
  { id: 's_n', label: 'S/N' },
  { id: 'paid_amount', label: 'Paid Amount' },
  { id: 'paid_date', label: 'Paid Date' },
]

//commission payment details table head cells
const headCellsCPD = [
  { id: 's_n', label: 'S/N' },
  { id: 'commission_amount', label: 'Commission Amount' },
  { id: 'paid_date', label: 'Paid Date' },
]

//enquiry activities table head cells
const headCellsEA = [
  { id: 'title', label: 'Title' },
  { id: 'comment', label: 'Comments' },
  { id: 'follow_up_date', label: 'Follow-Up Date' },
]

//enquiry table head cells
const headCells = [
  { id: 'datetime_of_referral', label: 'Referred Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'partner', label: 'Partner' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'status', label: 'Status' },
  { id: 'Details', label: 'Details' },
]

//appointment table head cells
const headCellsAppointment = [
  { id: 'datetime_of_referral', label: 'Referred Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'partner', label: 'Partner' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'demo_date', label: 'Demo Date' },
  { id: 'Details', label: 'Details' },
]

//follow-up table head cells
const headCellsFollowUp = [
  { id: 'datetime_of_referral', label: 'Referred Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'partner', label: 'Partner' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'follow_up_date', label: 'Follow Up Date' },
  { id: 'Details', label: 'Details' },
]

//on-hold table head cells
const headCellsOnHold = [
  { id: 'datetime_of_referral', label: 'Referred Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'partner', label: 'Partner' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'sales_amount', label: 'Sales Amount($)' },
  { id: 'Details', label: 'Details' },
]

//closed table head cells
const headCellsClosed = [
  { id: 'datetime_of_referral', label: 'Referred Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'partner', label: 'Partner' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'closed_date', label: 'Closed Date' },
  { id: 'sales_amount', label: 'Sales Amount($)' },
  { id: 'payment_received', label: 'Payment Received($)' },
  { id: 'commission_amount', label: 'Commission Amount($)' },
  { id: 'Details', label: 'Details' },
]

//lost table head cells
const headCellsLost = [
  { id: 'datetime_of_referral', label: 'Reffered Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'partner', label: 'Partner' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'sales_amount', label: 'Sales Amount($)' },
  { id: 'Details', label: 'Details' },
]


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));


export default function Home() {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [deleteNotify, setDeleteNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner, setIsPartner] = useState(false);
  const [isSalesperson, setIsSalesperson] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [totalEnquiry, setTotalEnquiry] = useState();
  const [commissionAmount, setCommissionAmount] = useState();
  const [saleAmount, setSaleAmount] = useState();
  const [sumPayment, setSumPayment] = useState();
  const [valueTabs, setValueTabs] = useState(0);
  const [viewEditPopup, setViewEditPopup] = useState(false);
  const [addPaymentPopup, setAddPaymentPopup] = useState(false);
  const [addCommissionPaymentPopup, setAddCommissionPaymentPopup] = useState(false);
  const [addEnquiryActivityPopup, setAddEnquiryActivityPopup] = useState(false);
  const [enquiryId, setEnquiryId] = useState();
  const [rangesSalesperson, setRangesSalesperson] = useState([]);
  const [rangesPartner, setRangesPartner] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState({
    isOpen: false,
    title: "",
  });


  const [selectedDate, setSelectedDate] = useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  //enquiry status
  const [rangesStatus, setRangesStatus] = useState([
    {
      value: 'Enquiry',
      label: 'Enquiry',
    },
    {
      value: 'Appointment',
      label: 'Appointment',
    },
    {
      value: 'Follow-Up',
      label: 'Follow-Up',
    },
    {
      value: 'On-Hold',
      label: 'On-Hold',
    },
    {
      value: 'Closed',
      label: 'Closed',
    },
    {
      value: 'Lost',
      label: 'Lost',
    }
  ]);

  //pagination
  const [showPaginationE, setShowPaginationE] = useState(false);
  const [showPaginationA, setShowPaginationA] = useState(false);
  const [showPaginationF, setShowPaginationF] = useState(false);
  const [showPaginationO, setShowPaginationO] = useState(false);
  const [showPaginationC, setShowPaginationC] = useState(false);
  const [showPaginationL, setShowPaginationL] = useState(false);
  const [showPaginationPD, setShowPaginationPD] = useState(false);
  const [showPaginationCPD, setShowPaginationCPD] = useState(false);
  const [showPaginationEA, setShowPaginationEA] = useState(false);

  //date filter
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showFilter, setShowFilter] = useState(false);

  //table records
  const [records, setRecords] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState(null);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });

  const [recordsAppointment, setRecordsAppointment] = useState([]);
  const [filterFnAppointment, setFilterFnAppointment] = useState({ fn: items => { return items; } });

  const [recordsFollowUp, setRecordsFollowUp] = useState([]);
  const [filterFnFollowUp, setFilterFnFollowUp] = useState({ fn: items => { return items; } });

  const [recordsOnHold, setRecordsOnHold] = useState([]);
  const [filterFnOnHold, setFilterFnOnHold] = useState({ fn: items => { return items; } });

  const [recordsClosed, setRecordsClosed] = useState([]);
  const [filterFnClosed, setFilterFnClosed] = useState({ fn: items => { return items; } });

  const [recordsLost, setRecordsLost] = useState([]);
  const [filterFnLost, setFilterFnLost] = useState({ fn: items => { return items; } });

  const [recordsPD, setRecordsPD] = useState([]);
  const [filterFnPD, setFilterFnPD] = useState({ fn: items => { return items; } });

  const [recordsCPD, setRecordsCPD] = useState([]);
  const [filterFnCPD, setFilterFnCPD] = useState({ fn: items => { return items; } });

  const [recordsEA, setRecordsEA] = useState([]);
  const [filterFnEA, setFilterFnEA] = useState({ fn: items => { return items; } });


  //all enquiries table
  const {
    TblContainer,
    TblPagination,
    TblHead,
    recordsAfterPagingAndSorting
  } = AllRecordsTbl(records, headCells, filterFn);

  //appointment table
  const {
    TblContainerAppointment,
    TblPaginationAppointment,
    TblHeadAppointment,
    paginSortingAppointment
  } = AppointmentTbl(recordsAppointment, headCellsAppointment, filterFnAppointment);

  //follow-up table
  const {
    TblContainerFollowUp,
    TblPaginationFollowUp,
    TblHeadFollowUp,
    paginSortingFollowUp
  } = FollowUpTbl(recordsFollowUp, headCellsFollowUp, filterFnFollowUp);

  //on-hold table
  const {
    TblContainerOnHold,
    TblPaginationOnHold,
    TblHeadOnHold,
    paginSortingOnHold
  } = OnHoldTbl(recordsOnHold, headCellsOnHold, filterFnOnHold);

  //closed table
  const {
    TblContainerClosed,
    TblPaginationClosed,
    TblHeadClosed,
    paginSortingClosed
  } = ClosedTbl(recordsClosed, headCellsClosed, filterFnClosed);

  //lost table
  const {
    TblContainerLost,
    TblPaginationLost,
    TblHeadLost,
    paginSortingLost
  } = LostTbl(recordsLost, headCellsLost, filterFnLost);

  //payment details table
  const {
    TblContainerPD,
    TblPaginationPD,
    TblHeadPD,
    paginSortingPD
  } = PaymentDetailsTbl(recordsPD, headCellsPD, filterFnPD);

  //payment details table
  const {
    TblContainerCPD,
    TblPaginationCPD,
    TblHeadCPD,
    paginSortingCPD
  } = CommissionPaymentDetailsTbl(recordsCPD, headCellsCPD, filterFnCPD);

  //enquiry activities table
  const {
    TblContainerEA,
    TblPaginationEA,
    TblHeadEA,
    paginSortingEA
  } = EnquiryActivitiesTbl(recordsEA, headCellsEA, filterFnEA);

  //enquiry form initial values
  const [initVal, setInitVal] = useState({
    company_name: "",
    contact_name: "",
    contact_email: "",
    mobile: "",
    sales_person: "",
    address: "",
    office_number: "",
    comments: "",
    status: "none",
    payment_received: "",
    sales_amount: "",
    commission_amount: "",
    follow_up_date: "",
    demo_date: "",
    comm_paid_date: "",
    closed_date: "",
  });

  //comments form initial values
  const initComVal = {
    partner_comments: "",
    follow_up_date_comments: new Date(),
  };

  //paid amount form initial values
  const initPaidAmountVal = {
    date_of_payment: new Date(),
    paid_amount: "",
    serial_number: "",
  };

  //commission paid amount form initial values
  const initCommissionPaidAmountVal = {
    date_of_payment: new Date(),
    commission_amount: "",
    serial_number: "",
  };

  //search status tbl
  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //search appointment tbl
  const handleSearchAppointment = e => {
    let target = e.target;
    setFilterFnAppointment({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //search follow-up tbl
  const handleSearchFollowUp = e => {
    let target = e.target;
    setFilterFnFollowUp({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //search on-hold tbl
  const handleSearchOnHold = e => {
    let target = e.target;
    setFilterFnOnHold({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //search closed tbl
  const handleSearchClosed = e => {
    let target = e.target;
    setFilterFnClosed({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //search lost tbl
  const handleSearchLost = e => {
    let target = e.target;
    setFilterFnLost({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //tabs
  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  //date filter
  const filterShow = () => {
    setShowFilter(!showFilter);

    //set to initial 
    setStartDate(new Date());
    setEndDate(new Date());

    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //fetch list of records
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry`).then((enquiryList) => {
            const responseRecords = enquiryList.data;
            const user_id = localStorage.getItem("user_id");
            let user_level = localStorage.getItem("user_level");

            /***all enquiries***/
            setRecords(responseRecords);

            /***appointment status***/
            let AppointmentArray = responseRecords.filter(function (appointment) {
              return appointment.status == "Appointment";
            });
            setRecordsAppointment(AppointmentArray);

            /***follow-up status***/
            let FollowUpArray = responseRecords.filter(function (followUp) {
              return followUp.status == "Follow-Up";
            });
            setRecordsFollowUp(FollowUpArray);

            /***on-hold status***/
            let OnHoldArray = responseRecords.filter(function (onHold) {
              return onHold.status == "On-Hold";
            });
            setRecordsOnHold(OnHoldArray);

            /***closed status***/
            let ClosedArray = responseRecords.filter(function (closed) {
              return closed.status == "Closed";
            });
            setRecordsClosed(ClosedArray);

            /***lost status***/
            let LostArray = responseRecords.filter(function (lost) {
              return lost.status == "Lost";
            });
            setRecordsLost(LostArray);


            /* if user level is partner then do filter by user_id
            ********************************************************/
            if (user_level == "Partner") {

              /*all enquiries*/
              let filteredAllEnquiry = [];
              for (let i = 0; i < responseRecords.length; i++) {
                if (responseRecords[i].user_id == user_id) {
                  filteredAllEnquiry.push(responseRecords[i]);
                }
              }
              setRecords(filteredAllEnquiry);

              /*appointment enquiry*/
              let filteredAppointmentEnquiry = [];
              for (let i = 0; i < AppointmentArray.length; i++) {
                if (AppointmentArray[i].user_id == user_id) {
                  filteredAppointmentEnquiry.push(AppointmentArray[i]);
                }
              }
              setRecordsAppointment(filteredAppointmentEnquiry);

              /*follow-up enquiry*/
              let filteredFollowUpEnquiry = [];
              for (let i = 0; i < FollowUpArray.length; i++) {
                if (FollowUpArray[i].user_id == user_id) {
                  filteredFollowUpEnquiry.push(FollowUpArray[i]);
                }
              }
              setRecordsFollowUp(filteredFollowUpEnquiry);

              /*on-hold enquiry*/
              let filteredOnHoldEnquiry = [];
              for (let i = 0; i < OnHoldArray.length; i++) {
                if (OnHoldArray[i].user_id == user_id) {
                  filteredOnHoldEnquiry.push(OnHoldArray[i]);
                }
              }
              setRecordsOnHold(filteredOnHoldEnquiry);

              /*closed enquiry*/
              let filteredClosedEnquiry = [];
              for (let i = 0; i < ClosedArray.length; i++) {
                if (ClosedArray[i].user_id == user_id) {
                  filteredClosedEnquiry.push(ClosedArray[i]);
                }
              }
              setRecordsClosed(filteredClosedEnquiry);

              /*lost enquiry*/
              let filteredLostEnquiry = [];
              for (let i = 0; i < LostArray.length; i++) {
                if (LostArray[i].user_id == user_id) {
                  filteredLostEnquiry.push(LostArray[i]);
                }
              }
              setRecordsLost(filteredLostEnquiry);

              //pagination
              let countE = Object.keys(filteredAllEnquiry).length;
              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

              let countA = Object.keys(filteredAppointmentEnquiry).length;
              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

              let countF = Object.keys(filteredFollowUpEnquiry).length;
              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

              let countO = Object.keys(filteredOnHoldEnquiry).length;
              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

              let countC = Object.keys(filteredClosedEnquiry).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

              let countL = Object.keys(filteredLostEnquiry).length;
              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);

            }
            else {
              //pagination
              let countE = Object.keys(responseRecords).length;
              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

              let countA = Object.keys(AppointmentArray).length;
              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

              let countF = Object.keys(FollowUpArray).length;
              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

              let countO = Object.keys(OnHoldArray).length;
              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

              let countC = Object.keys(ClosedArray).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

              let countL = Object.keys(LostArray).length;
              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);
            }


          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }

  const DateFilter = () => {
    return (
      <div className='date-filter-wrap'>
        <DateRangePicker ranges={
          [selectionRange]}
          onChange={handleSelect}
        />
        <Button variant="contained" color="secondary" size="small" onClick={filterDates}>
          Search
        </Button>
      </div>
    )
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  const filterDates = () => {
    setShowFilter(!showFilter);

    //set startdate time 
    let modifiedStartDate = format(startDate, 'yyyy-MM-dd');
    modifiedStartDate = modifiedStartDate + " " + "00:00:00";
    modifiedStartDate = new Date(modifiedStartDate);

    //set enddate time 
    let modifiedEndDate = format(endDate, 'yyyy-MM-dd');
    modifiedEndDate = modifiedEndDate + " " + "23:59:59";
    modifiedEndDate = new Date(modifiedEndDate);

    //all enquiries
    let filteredRecords = records.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecords(filteredRecords);

    let countE = Object.keys(filteredRecords).length;
    if (countE > 0) {
      setShowPaginationE(false);
    }
    else {
      setShowPaginationE(true);
    }

    //appointment
    let filteredRecordsAppointment = recordsAppointment.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecordsAppointment(filteredRecordsAppointment);

    let countA = Object.keys(filteredRecordsAppointment).length;
    if (countA > 0) {
      setShowPaginationA(false);
    }
    else {
      setShowPaginationA(true);
    }

    //follow-up
    let filteredRecordsFollowUp = recordsFollowUp.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecordsFollowUp(filteredRecordsFollowUp);

    //on-hold
    let filteredRecordsOnHold = recordsOnHold.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecordsOnHold(filteredRecordsOnHold);

    let countO = Object.keys(filteredRecordsOnHold).length;
    if (countO > 0) {
      setShowPaginationO(false);
    }
    else {
      setShowPaginationO(true);
    }

    //closed
    let filteredRecordsClosed = recordsClosed.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecordsClosed(filteredRecordsClosed);

    let countC = Object.keys(filteredRecordsClosed).length;
    if (countC > 0) {
      setShowPaginationC(false);
    }
    else {
      setShowPaginationC(true);
    }

    //lost
    let filteredRecordsLost = recordsLost.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecordsLost(filteredRecordsLost);

    let countL = Object.keys(filteredRecordsLost).length;
    if (countL > 0) {
      setShowPaginationL(false);
    }
    else {
      setShowPaginationL(true);
    }

  }

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user_level = localStorage.getItem("user_level");
    let user_id = localStorage.getItem("user_id");

    //check user level
    if (user_level == "Partner") {
      document.body.classList.add("user");
    }
    else {
      document.body.classList.add("admin");
    }


    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //check user level
          if (user_level == "Admin") {
            setIsAdmin(true);
            //fetch total enquiry, sale amount, total commission
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/showtotal`).then((resTotal) => {
              if (resTotal.data[0].total_id == null) {
                resTotal.data[0].total_id = 0;
              }
              if (resTotal.data[0].total_commission_amount == null) {
                resTotal.data[0].total_commission_amount = "0.00";
              }
              if (resTotal.data[0].total_sales_amount == null) {
                resTotal.data[0].total_sales_amount = "0.00";
              }
              //set analytical data
              setTotalEnquiry(resTotal.data[0].total_id);
              setCommissionAmount(resTotal.data[0].total_commission_amount);
              setSaleAmount(resTotal.data[0].total_sales_amount);
            });
          }
          else {
            if (user_level == "Salesperson") {
              setIsSalesperson(true)
            }
            if (user_level == "Partner") {
              setIsPartner(true)
            }
            //fetch total enquiry, sale amount, total commission
            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/showtotal/${user_id}`).then((resTotal) => {
              if (resTotal.data[0].total_id == null) {
                resTotal.data[0].total_id = 0;
              }
              if (resTotal.data[0].total_commission_amount == null) {
                resTotal.data[0].total_commission_amount = "0.00";
              }
              if (resTotal.data[0].total_sales_amount == null) {
                resTotal.data[0].total_sales_amount = "0.00";
              }
              //set analytical data
              setTotalEnquiry(resTotal.data[0].total_id);
              setCommissionAmount(resTotal.data[0].total_commission_amount);
              setSaleAmount(resTotal.data[0].total_sales_amount);
            });
          }

          //fetch list of salesperson
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/salespersons`).then((resSalesperson) => {
            setRangesSalesperson(resSalesperson.data);
          });

          //fetch list of partner
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/partners`).then((resPartner) => {
            setRangesPartner(resPartner.data);
          });

          //fetch list of records
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry`).then((enquiryList) => {
            const responseRecords = enquiryList.data;
            const user_id = localStorage.getItem("user_id");

            /***all enquiries***/
            setRecords(responseRecords);

            /***appointment status***/
            let AppointmentArray = responseRecords.filter(function (appointment) {
              return appointment.status == "Appointment";
            });
            setRecordsAppointment(AppointmentArray);

            /***follow-up status***/
            let FollowUpArray = responseRecords.filter(function (followUp) {
              return followUp.status == "Follow-Up";
            });
            setRecordsFollowUp(FollowUpArray);

            /***on-hold status***/
            let OnHoldArray = responseRecords.filter(function (onHold) {
              return onHold.status == "On-Hold";
            });
            setRecordsOnHold(OnHoldArray);

            /***closed status***/
            let ClosedArray = responseRecords.filter(function (closed) {
              return closed.status == "Closed";
            });
            setRecordsClosed(ClosedArray);

            /***lost status***/
            let LostArray = responseRecords.filter(function (lost) {
              return lost.status == "Lost";
            });
            setRecordsLost(LostArray);


            /* if user level is partner then do filter by user_id
            ********************************************************/
            if (user_level == "Partner") {

              /*all enquiries*/
              let filteredAllEnquiry = [];
              for (let i = 0; i < responseRecords.length; i++) {
                if (responseRecords[i].user_id == user_id) {
                  filteredAllEnquiry.push(responseRecords[i]);
                }
              }
              setRecords(filteredAllEnquiry);

              /*appointment enquiry*/
              let filteredAppointmentEnquiry = [];
              for (let i = 0; i < AppointmentArray.length; i++) {
                if (AppointmentArray[i].user_id == user_id) {
                  filteredAppointmentEnquiry.push(AppointmentArray[i]);
                }
              }
              setRecordsAppointment(filteredAppointmentEnquiry);

              /*follow-up enquiry*/
              let filteredFollowUpEnquiry = [];
              for (let i = 0; i < FollowUpArray.length; i++) {
                if (FollowUpArray[i].user_id == user_id) {
                  filteredFollowUpEnquiry.push(FollowUpArray[i]);
                }
              }
              setRecordsFollowUp(filteredFollowUpEnquiry);

              /*on-hold enquiry*/
              let filteredOnHoldEnquiry = [];
              for (let i = 0; i < OnHoldArray.length; i++) {
                if (OnHoldArray[i].user_id == user_id) {
                  filteredOnHoldEnquiry.push(OnHoldArray[i]);
                }
              }
              setRecordsOnHold(filteredOnHoldEnquiry);

              /*closed enquiry*/
              let filteredClosedEnquiry = [];
              for (let i = 0; i < ClosedArray.length; i++) {
                if (ClosedArray[i].user_id == user_id) {
                  filteredClosedEnquiry.push(ClosedArray[i]);
                }
              }
              setRecordsClosed(filteredClosedEnquiry);

              /*lost enquiry*/
              let filteredLostEnquiry = [];
              for (let i = 0; i < LostArray.length; i++) {
                if (LostArray[i].user_id == user_id) {
                  filteredLostEnquiry.push(LostArray[i]);
                }
              }
              setRecordsLost(filteredLostEnquiry);

              //pagination
              let countE = Object.keys(filteredAllEnquiry).length;
              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

              let countA = Object.keys(filteredAppointmentEnquiry).length;
              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

              let countF = Object.keys(filteredFollowUpEnquiry).length;
              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

              let countO = Object.keys(filteredOnHoldEnquiry).length;
              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

              let countC = Object.keys(filteredClosedEnquiry).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

              let countL = Object.keys(filteredLostEnquiry).length;
              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);

            }
            else if (user_level == "Salesperson") {

              /*all enquiries*/
              let filteredAllEnquiry = [];
              for (let i = 0; i < responseRecords.length; i++) {
                if (responseRecords[i].salesperson_id == user_id) {
                  filteredAllEnquiry.push(responseRecords[i]);
                }
              }
              setRecords(filteredAllEnquiry);

              /*appointment enquiry*/
              let filteredAppointmentEnquiry = [];
              for (let i = 0; i < AppointmentArray.length; i++) {
                if (AppointmentArray[i].salesperson_id == user_id) {
                  filteredAppointmentEnquiry.push(AppointmentArray[i]);
                }
              }
              setRecordsAppointment(filteredAppointmentEnquiry);

              /*follow-up enquiry*/
              let filteredFollowUpEnquiry = [];
              for (let i = 0; i < FollowUpArray.length; i++) {
                if (FollowUpArray[i].salesperson_id == user_id) {
                  filteredFollowUpEnquiry.push(FollowUpArray[i]);
                }
              }
              setRecordsFollowUp(filteredFollowUpEnquiry);

              /*on-hold enquiry*/
              let filteredOnHoldEnquiry = [];
              for (let i = 0; i < OnHoldArray.length; i++) {
                if (OnHoldArray[i].salesperson_id == user_id) {
                  filteredOnHoldEnquiry.push(OnHoldArray[i]);
                }
              }
              setRecordsOnHold(filteredOnHoldEnquiry);

              /*closed enquiry*/
              let filteredClosedEnquiry = [];
              for (let i = 0; i < ClosedArray.length; i++) {
                if (ClosedArray[i].salesperson_id == user_id) {
                  filteredClosedEnquiry.push(ClosedArray[i]);
                }
              }
              setRecordsClosed(filteredClosedEnquiry);

              /*lost enquiry*/
              let filteredLostEnquiry = [];
              for (let i = 0; i < LostArray.length; i++) {
                if (LostArray[i].salesperson_id == user_id) {
                  filteredLostEnquiry.push(LostArray[i]);
                }
              }
              setRecordsLost(filteredLostEnquiry);

              //pagination
              let countE = Object.keys(filteredAllEnquiry).length;
              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

              let countA = Object.keys(filteredAppointmentEnquiry).length;
              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

              let countF = Object.keys(filteredFollowUpEnquiry).length;
              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

              let countO = Object.keys(filteredOnHoldEnquiry).length;
              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

              let countC = Object.keys(filteredClosedEnquiry).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

              let countL = Object.keys(filteredLostEnquiry).length;
              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);

            }
            else {
              //pagination
              let countE = Object.keys(responseRecords).length;
              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

              let countA = Object.keys(AppointmentArray).length;
              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

              let countF = Object.keys(FollowUpArray).length;
              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

              let countO = Object.keys(OnHoldArray).length;
              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

              let countC = Object.keys(ClosedArray).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

              let countL = Object.keys(LostArray).length;
              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);
            }

          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }, []);


  //show view edit popup
  const showPopup = (row) => {
    let currentUsername = localStorage.getItem("username");
    let token = localStorage.getItem("token");

    //add separator to the numbers
    let commAmountSeparator = parseFloat(row.commission_amount).toLocaleString();
    let salesAmountSeparator = parseFloat(row.sales_amount).toLocaleString();
    let paidsumSeparator = parseFloat(row.paidsum).toLocaleString();
    let commissionSumSeparator = parseFloat(row.commission_sum).toLocaleString();

    //update value of input fields based on enquiry id
    setInitVal({
      address: row.address,
      closed_date: row.closed_date,
      comm_paid_date: row.comm_paid_date,
      comments: row.comments,
      commission_amount: commAmountSeparator,
      company_name: row.company_name,
      contact_email: row.contact_email,
      contact_name: row.contact_name,
      datetime_of_creation: row.datetime_of_creation,
      datetime_of_referral: row.datetime_of_referral,
      demo_date: row.demo_date,
      follow_up_date: row.follow_up_date,
      id: row.id,
      mobile: row.mobile,
      office_number: row.office_number,
      paidsum: paidsumSeparator,
      commission_sum: commissionSumSeparator,
      sales_amount: salesAmountSeparator,
      sales_person: row.sales_person,
      status: row.status,
      user_id: row.user_id,
      username: row.username,
      salesperson_id: row.salesperson_id,
    });

    setCurrentUser(currentUsername);
    setViewEditPopup(true);
    setEnquiryId(row.id);

    //fetch current status
    for (var i = rangesStatus.length - 1; i >= 0; --i) {
      if (rangesStatus[i].value == row.status) {
        rangesStatus.splice(i, 1);
        rangesStatus.push({ value: row.status, label: row.status })
        setRangesStatus(rangesStatus);
      }
    }

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {

          //fetch sum of payment based on enquiry id
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry/${row.id}`).then((res) => {
            let paymentReceivedSeparator = parseFloat(res.data[0].payment_received).toLocaleString();
            setSumPayment(paymentReceivedSeparator);
          });

          //fetch enquiry activities based on the enquiry id
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry_activities/${row.id}`).then((enquiry_activities) => {
            setRecordsEA(enquiry_activities.data);
            let countEA = Object.keys(enquiry_activities.data).length;
            if (countEA > 0) {
              setShowPaginationEA(true);
            }
            else {
              setShowPaginationEA(false);
            }
          });

          //fetch payment details based on the enquiry id 
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/payment/${row.id}`).then((payment_details) => {
            setRecordsPD(payment_details.data);
            let countPd = Object.keys(payment_details.data).length;
            if (countPd) {
              setShowPaginationPD(true);
            }
            else {
              setShowPaginationPD(false);
            }
          });

          //fetch payment details based on the enquiry id 
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/commission_payment/${row.id}`).then((commission_details) => {
            setRecordsCPD(commission_details.data);
            let countCpd = Object.keys(commission_details.data).length;
            if (countCpd) {
              setShowPaginationCPD(true);
            }
            else {
              setShowPaginationCPD(false);
            }
          });

        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  };

  //confirm delete enquiry
  const confirmDelEnquiry = (row) => {
    let token = localStorage.getItem("token");
    let user_level = localStorage.getItem("user_level");
    let user_id = localStorage.getItem("user_id");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //delete enquiry based on the enquiry id
          axios.delete(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry/delete/${row.id}`).then((resDelete) => {

            if (resDelete.data === 1) {
              //refresh total enquiry, sale amount, total commission
              if (user_level == "Admin") {
                setIsAdmin(true);
                axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/showtotal`).then((resTotal) => {
                  if (resTotal.data[0].total_id == null) {
                    resTotal.data[0].total_id = 0;
                  }
                  if (resTotal.data[0].total_commission_amount == null) {
                    resTotal.data[0].total_commission_amount = "0.00";
                  }
                  if (resTotal.data[0].total_sales_amount == null) {
                    resTotal.data[0].total_sales_amount = "0.00";
                  }
                  //set analytical data
                  setTotalEnquiry(resTotal.data[0].total_id);
                  setCommissionAmount(resTotal.data[0].total_commission_amount);
                  setSaleAmount(resTotal.data[0].total_sales_amount);
                });
              }
              else {
                axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/showtotal/${user_id}`).then((resTotal) => {
                  if (resTotal.data[0].total_id == null) {
                    resTotal.data[0].total_id = 0;
                  }
                  if (resTotal.data[0].total_commission_amount == null) {
                    resTotal.data[0].total_commission_amount = "0.00";
                  }
                  if (resTotal.data[0].total_sales_amount == null) {
                    resTotal.data[0].total_sales_amount = "0.00";
                  }
                  //set analytical data
                  setTotalEnquiry(resTotal.data[0].total_id);
                  setCommissionAmount(resTotal.data[0].total_commission_amount);
                  setSaleAmount(resTotal.data[0].total_sales_amount);
                });
              }
              //fetch list of records
              axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry`).then((enquiryList) => {
                const responseRecords = enquiryList.data;

                //filter all status
                setRecords(responseRecords);

                let countE = Object.keys(responseRecords).length;
                if (countE > 0) {
                  setShowPaginationE(false);
                }
                else {
                  setShowPaginationE(true);
                }

                //filter appointment status
                let AppointmentArray = responseRecords.filter(function (appointment) {
                  return appointment.status == "Appointment";
                });
                setRecordsAppointment(AppointmentArray);

                let countA = Object.keys(AppointmentArray).length;
                if (countA > 0) {
                  setShowPaginationA(false);
                }
                else {
                  setShowPaginationA(true);
                }

                //filter follow-up status
                let FollowUpArray = responseRecords.filter(function (followUp) {
                  return followUp.status == "Follow-Up";
                });
                setRecordsFollowUp(FollowUpArray);

                let countF = Object.keys(FollowUpArray).length;
                if (countF > 0) {
                  setShowPaginationF(false);
                }
                else {
                  setShowPaginationF(true);
                }

                //filter on-hold status
                let OnHoldArray = responseRecords.filter(function (onHold) {
                  return onHold.status == "On-Hold";
                });
                setRecordsOnHold(OnHoldArray);

                let countO = Object.keys(OnHoldArray).length;
                if (countO > 0) {
                  setShowPaginationO(false);
                }
                else {
                  setShowPaginationO(true);
                }

                //filter closed status
                let ClosedArray = responseRecords.filter(function (closed) {
                  return closed.status == "Closed";
                });
                setRecordsClosed(ClosedArray);

                let countC = Object.keys(ClosedArray).length;
                if (countC > 0) {
                  setShowPaginationC(false);
                }
                else {
                  setShowPaginationC(true);
                }

                //filter lost status
                let LostArray = responseRecords.filter(function (lost) {
                  return lost.status == "Lost";
                });
                setRecordsLost(LostArray);

                let countL = Object.keys(LostArray).length;
                if (countL > 0) {
                  setShowPaginationL(false);
                }
                else {
                  setShowPaginationL(true);
                }

              });
              setConfirmDelete({
                ...confirmDelete,
                isOpen: false,
              });
              setDeleteNotify({
                isOpen: true,
                message: `Successfully deleted the enquiry.`,
                type: "success",
              });
            }
            else {
              setConfirmDelete({
                ...confirmDelete,
                isOpen: false,
              });
              setDeleteNotify({
                isOpen: true,
                message: `Unable to process your request`,
                type: "error",
              });
            }

          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  };

  //close view edit popup
  const closeViewEditPopup = () => {
    setViewEditPopup(false);
  };


  return (
    <React.Fragment>
      <div className="home container">
        <ConfirmDialog
          confirmDialog={confirmDelete}
          setConfirmDialog={setConfirmDelete}
        />
        <Notification notify={notify} setNotify={setNotify} />
        <Notification notify={deleteNotify} setNotify={setDeleteNotify} />

        <Popup
          openPopup={viewEditPopup}
          setOpenPopup={setViewEditPopup}
          title={
            <div className="popup-title-bar">
              INFORMATION
              <Button
                onClick={() => {
                  closeViewEditPopup();
                }}
                className="pop-close"
              >
                x
              </Button>
            </div>
          }
        >
          <Formik
            initialValues={initVal}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {

                let token = localStorage.getItem("token");
                let user_id = localStorage.getItem("user_id");
                let user_level = localStorage.getItem("user_level");

                //authentication
                axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                  auth: token,
                })
                  .then(auth => {
                    if (auth.data == true) {

                      //follow up date
                      if (values.follow_up_date != null) {
                        if (typeof (values.follow_up_date) == "string") {
                          values.follow_up_date = new Date(values.follow_up_date);
                        }
                        values.follow_up_date = format(values.follow_up_date, 'yyyy-MM-dd');
                      }

                      //demo date
                      if (values.demo_date != null) {
                        if (typeof (values.demo_date) == "string") {
                          values.demo_date = new Date(values.demo_date);
                        }
                        values.demo_date = format(values.demo_date, 'yyyy-MM-dd');
                      }

                      //Commission paid date
                      if (values.comm_paid_date != null) {
                        if (typeof (values.comm_paid_date) == "string") {
                          values.comm_paid_date = new Date(values.comm_paid_date);
                        }
                        values.comm_paid_date = format(values.comm_paid_date, 'yyyy-MM-dd');
                      }

                      //closed date
                      if (values.closed_date != null) {
                        if (typeof (values.closed_date) == "string") {
                          values.closed_date = new Date(values.closed_date);
                        }
                        values.closed_date = format(values.closed_date, 'yyyy-MM-dd');
                      }

                      let partner_id = null
                      if (isAdmin) {
                        if (values.user_id) {
                          partner_id = values.user_id
                        }
                      }

                      axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry/update/${enquiryId}`, {
                        company_name: values.company_name,
                        contact_name: values.contact_name,
                        mobile: values.mobile,
                        contact_email: values.contact_email,
                        sales_person: values.sales_person,
                        salesperson_id: values.salesperson_id,
                        partner_id: partner_id,
                        address: values.address,
                        office_number: values.office_number,
                        sales_amount: parseInt(values.sales_amount.replace(/,/g, '')),
                        commission_amount: parseInt(values.commission_amount.replace(/,/g, '')),
                        status: values.status,
                        follow_up_date: values.follow_up_date,
                        demo_date: values.demo_date,
                        comm_paid_date: values.comm_paid_date,
                        closed_date: values.closed_date,
                        comments: values.comments,
                        name: localStorage.getItem("name")
                      })
                        .then(res => {
                          //refresh total enquiry, sale amount, total commission
                          if (user_level == "Admin") {
                            setIsAdmin(true);
                            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/showtotal`).then((resTotal) => {
                              if (resTotal.data[0].total_id == null) {
                                resTotal.data[0].total_id = 0;
                              }
                              if (resTotal.data[0].total_commission_amount == null) {
                                resTotal.data[0].total_commission_amount = "0.00";
                              }
                              if (resTotal.data[0].total_sales_amount == null) {
                                resTotal.data[0].total_sales_amount = "0.00";
                              }
                              //set analytical data
                              setTotalEnquiry(resTotal.data[0].total_id);
                              setCommissionAmount(resTotal.data[0].total_commission_amount);
                              setSaleAmount(resTotal.data[0].total_sales_amount);
                            });
                          }
                          else {
                            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/showtotal/${user_id}`).then((resTotal) => {
                              if (resTotal.data[0].total_id == null) {
                                resTotal.data[0].total_id = 0;
                              }
                              if (resTotal.data[0].total_commission_amount == null) {
                                resTotal.data[0].total_commission_amount = "0.00";
                              }
                              if (resTotal.data[0].total_sales_amount == null) {
                                resTotal.data[0].total_sales_amount = "0.00";
                              }
                              //set analytical data
                              setTotalEnquiry(resTotal.data[0].total_id);
                              setCommissionAmount(resTotal.data[0].total_commission_amount);
                              setSaleAmount(resTotal.data[0].total_sales_amount);
                            });
                          }

                          //update list of records
                          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry`).then((enquiryList) => {
                            const responseRecords = enquiryList.data;

                            //filter all status
                            setRecords(responseRecords);

                            let countE = Object.keys(responseRecords).length;
                            if (countE > 0) {
                              setShowPaginationE(false);
                            }
                            else {
                              setShowPaginationE(true);
                            }

                            //filter appointment status
                            let AppointmentArray = responseRecords.filter(function (appointment) {
                              return appointment.status == "Appointment";
                            });
                            setRecordsAppointment(AppointmentArray);

                            let countA = Object.keys(AppointmentArray).length;
                            if (countA > 0) {
                              setShowPaginationA(false);
                            }
                            else {
                              setShowPaginationA(true);
                            }

                            //filter follow-up status
                            let FollowUpArray = responseRecords.filter(function (followUp) {
                              return followUp.status == "Follow-Up";
                            });
                            setRecordsFollowUp(FollowUpArray);

                            let countF = Object.keys(FollowUpArray).length;
                            if (countF > 0) {
                              setShowPaginationF(false);
                            }
                            else {
                              setShowPaginationF(true);
                            }

                            //filter on-hold status
                            let OnHoldArray = responseRecords.filter(function (onHold) {
                              return onHold.status == "On-Hold";
                            });
                            setRecordsOnHold(OnHoldArray);

                            let countO = Object.keys(OnHoldArray).length;
                            if (countO > 0) {
                              setShowPaginationO(false);
                            }
                            else {
                              setShowPaginationO(true);
                            }

                            //filter closed status
                            let ClosedArray = responseRecords.filter(function (closed) {
                              return closed.status == "Closed";
                            });
                            setRecordsClosed(ClosedArray);

                            let countC = Object.keys(ClosedArray).length;
                            if (countC > 0) {
                              setShowPaginationC(false);
                            }
                            else {
                              setShowPaginationC(true);
                            }

                            //filter lost status
                            let LostArray = responseRecords.filter(function (lost) {
                              return lost.status == "Lost";
                            });
                            setRecordsLost(LostArray);

                            let countL = Object.keys(LostArray).length;
                            if (countL > 0) {
                              setShowPaginationL(false);
                            }
                            else {
                              setShowPaginationL(true);
                            }


                            /* if user level is partner then do filter by user_id
            ********************************************************/
                            if (user_level == "Partner") {

                              /*all enquiries*/
                              let filteredAllEnquiry = [];
                              for (let i = 0; i < responseRecords.length; i++) {
                                if (responseRecords[i].user_id == user_id) {
                                  filteredAllEnquiry.push(responseRecords[i]);
                                }
                              }
                              setRecords(filteredAllEnquiry);

                              /*appointment enquiry*/
                              let filteredAppointmentEnquiry = [];
                              for (let i = 0; i < AppointmentArray.length; i++) {
                                if (AppointmentArray[i].user_id == user_id) {
                                  filteredAppointmentEnquiry.push(AppointmentArray[i]);
                                }
                              }
                              setRecordsAppointment(filteredAppointmentEnquiry);

                              /*follow-up enquiry*/
                              let filteredFollowUpEnquiry = [];
                              for (let i = 0; i < FollowUpArray.length; i++) {
                                if (FollowUpArray[i].user_id == user_id) {
                                  filteredFollowUpEnquiry.push(FollowUpArray[i]);
                                }
                              }
                              setRecordsFollowUp(filteredFollowUpEnquiry);

                              /*on-hold enquiry*/
                              let filteredOnHoldEnquiry = [];
                              for (let i = 0; i < OnHoldArray.length; i++) {
                                if (OnHoldArray[i].user_id == user_id) {
                                  filteredOnHoldEnquiry.push(OnHoldArray[i]);
                                }
                              }
                              setRecordsOnHold(filteredOnHoldEnquiry);

                              /*closed enquiry*/
                              let filteredClosedEnquiry = [];
                              for (let i = 0; i < ClosedArray.length; i++) {
                                if (ClosedArray[i].user_id == user_id) {
                                  filteredClosedEnquiry.push(ClosedArray[i]);
                                }
                              }
                              setRecordsClosed(filteredClosedEnquiry);

                              /*lost enquiry*/
                              let filteredLostEnquiry = [];
                              for (let i = 0; i < LostArray.length; i++) {
                                if (LostArray[i].user_id == user_id) {
                                  filteredLostEnquiry.push(LostArray[i]);
                                }
                              }
                              setRecordsLost(filteredLostEnquiry);

                              //pagination
                              let countE = Object.keys(filteredAllEnquiry).length;
                              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

                              let countA = Object.keys(filteredAppointmentEnquiry).length;
                              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

                              let countF = Object.keys(filteredFollowUpEnquiry).length;
                              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

                              let countO = Object.keys(filteredOnHoldEnquiry).length;
                              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

                              let countC = Object.keys(filteredClosedEnquiry).length;
                              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

                              let countL = Object.keys(filteredLostEnquiry).length;
                              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);

                            }
                            else if (user_level == "Salesperson") {

                              /*all enquiries*/
                              let filteredAllEnquiry = [];
                              for (let i = 0; i < responseRecords.length; i++) {
                                if (responseRecords[i].salesperson_id == user_id) {
                                  filteredAllEnquiry.push(responseRecords[i]);
                                }
                              }
                              setRecords(filteredAllEnquiry);

                              /*appointment enquiry*/
                              let filteredAppointmentEnquiry = [];
                              for (let i = 0; i < AppointmentArray.length; i++) {
                                if (AppointmentArray[i].salesperson_id == user_id) {
                                  filteredAppointmentEnquiry.push(AppointmentArray[i]);
                                }
                              }
                              setRecordsAppointment(filteredAppointmentEnquiry);

                              /*follow-up enquiry*/
                              let filteredFollowUpEnquiry = [];
                              for (let i = 0; i < FollowUpArray.length; i++) {
                                if (FollowUpArray[i].salesperson_id == user_id) {
                                  filteredFollowUpEnquiry.push(FollowUpArray[i]);
                                }
                              }
                              setRecordsFollowUp(filteredFollowUpEnquiry);

                              /*on-hold enquiry*/
                              let filteredOnHoldEnquiry = [];
                              for (let i = 0; i < OnHoldArray.length; i++) {
                                if (OnHoldArray[i].salesperson_id == user_id) {
                                  filteredOnHoldEnquiry.push(OnHoldArray[i]);
                                }
                              }
                              setRecordsOnHold(filteredOnHoldEnquiry);

                              /*closed enquiry*/
                              let filteredClosedEnquiry = [];
                              for (let i = 0; i < ClosedArray.length; i++) {
                                if (ClosedArray[i].salesperson_id == user_id) {
                                  filteredClosedEnquiry.push(ClosedArray[i]);
                                }
                              }
                              setRecordsClosed(filteredClosedEnquiry);

                              /*lost enquiry*/
                              let filteredLostEnquiry = [];
                              for (let i = 0; i < LostArray.length; i++) {
                                if (LostArray[i].salesperson_id == user_id) {
                                  filteredLostEnquiry.push(LostArray[i]);
                                }
                              }
                              setRecordsLost(filteredLostEnquiry);

                              //pagination
                              let countE = Object.keys(filteredAllEnquiry).length;
                              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

                              let countA = Object.keys(filteredAppointmentEnquiry).length;
                              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

                              let countF = Object.keys(filteredFollowUpEnquiry).length;
                              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

                              let countO = Object.keys(filteredOnHoldEnquiry).length;
                              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

                              let countC = Object.keys(filteredClosedEnquiry).length;
                              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

                              let countL = Object.keys(filteredLostEnquiry).length;
                              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);

                            }
                            else {
                              //pagination
                              let countE = Object.keys(responseRecords).length;
                              (countE > 0) ? setShowPaginationE(false) : setShowPaginationE(true);

                              let countA = Object.keys(AppointmentArray).length;
                              (countA > 0) ? setShowPaginationA(false) : setShowPaginationA(true);

                              let countF = Object.keys(FollowUpArray).length;
                              (countF > 0) ? setShowPaginationF(false) : setShowPaginationF(true);

                              let countO = Object.keys(OnHoldArray).length;
                              (countO > 0) ? setShowPaginationO(false) : setShowPaginationO(true);

                              let countC = Object.keys(ClosedArray).length;
                              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);

                              let countL = Object.keys(LostArray).length;
                              (countL > 0) ? setShowPaginationL(false) : setShowPaginationL(true);
                            }

                          });
                          setSubmitting(false);
                          // setViewEditPopup(false);
                          setNotify({
                            isOpen: true,
                            message: `Enquiry has been successfully modified.`,
                            type: "success",
                          });
                        })
                        .catch(error => {
                          setSubmitting(false);
                          setViewEditPopup(false);
                          setNotify({
                            isOpen: true,
                            message: `Invalid Request.`,
                            type: "error",
                          });
                          console.log(error);
                        });

                    }
                    else {
                      localStorage.clear();
                      window.location.replace("/");
                    }
                  })
                  .catch(error => {
                    setSubmitting(false);
                    setViewEditPopup(false);
                    console.log(error)
                  });

              }, 500);
            }}
            //validation
            validate={values => {
              const errors: Partial<Values> = {};
              if (!values.company_name) {
                errors.company_name = 'Required';
              }
              if (!values.contact_name) {
                errors.contact_name = 'Required';
              }

              // if (!values.mobile) {
              //   errors.mobile = 'Required';
              // }

              // if (!values.contact_email) {
              //   errors.contact_email = 'Required';
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)
              // ) {
              //   errors.contact_email = 'Invalid email address';
              // }

              // if (!values.address) {
              //   errors.address = 'Required';
              // }

              // if (!values.office_number) {
              //   errors.office_number = 'Required';
              // }

              if (!values.sales_amount) {
                errors.sales_amount = 'Required';
              }
              if (!values.commission_amount) {
                errors.commission_amount = 'Required';
              }
              if (!values.status) {
                errors.status = 'Required';
              }
              if (!values.comments) {
                errors.comments = 'Required';
              }

              return errors;
            }}
          >

            {({ submitForm, isSubmitting }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form className='viewEditForm info-popup-wrap'>
                  {isSubmitting && <LinearProgress />}

                  <Typography
                    variant="subtitle"
                    component="div"
                    className="popup-label"
                  >
                    Customer Details
                  </Typography>

                  <Grid container spacing={2}>
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Company Name"
                          name="company_name"
                        />
                      </Grid>
                    }
                    {!isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Company Name"
                          name="company_name"
                          disabled
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Contact Person"
                          name="contact_name"
                        />
                      </Grid>
                    }
                    {!isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Contact Person"
                          name="contact_name"
                          disabled
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Phone Mobile"
                          name="mobile"
                        />
                      </Grid>
                    }
                    {!isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Phone Mobile"
                          name="mobile"
                          disabled
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Contact Email"
                          name="contact_email"
                        />
                      </Grid>
                    }
                    {!isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Contact Email"
                          name="contact_email"
                          disabled
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          select
                          name="salesperson_id"
                          label="Sales Person"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {rangesSalesperson.map((option) => (
                            <MenuItem key={option.user_id} value={option.user_id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    }
                    {!isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          select
                          disabled
                          name="salesperson_id"
                          label="Sales Person"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {rangesSalesperson.map((option) => (
                            <MenuItem key={option.user_id} value={option.user_id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    }

                    {(isAdmin || isSalesperson) &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          select
                          disabled={isSalesperson}
                          name="user_id"
                          label="Partner"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {rangesPartner.map((option) => (
                            <MenuItem key={option.user_id} value={option.user_id}>
                              {option.username}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    }

                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Address"
                          name="address"
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Office Number"
                          name="office_number"
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Sales Amount"
                          name="sales_amount"
                        />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Commission Amount"
                          name="commission_amount"
                        />
                      </Grid>
                    }

                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Payment Received"
                          name="paidsum"
                          disabled
                        />
                      </Grid>
                    }

                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          label="Commission Received"
                          name="commission_sum"
                          disabled
                        />
                      </Grid>
                    }


                    {/* {isAdmin &&
                      <Grid item xs={3} className="payment-received">
                        <TextField
                          label="Payment Received"
                          name="commission_amount"
                          disabled
                        />
                      </Grid>

                    } */}
                    {isAdmin || isPartner || isSalesperson &&
                      <Grid item xs={3}>
                        <Field
                          component={TextFieldFormik}
                          type="text"
                          select
                          name="status"
                          label="Status"
                          variant="standard"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={isPartner}
                        >
                          {rangesStatus.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field component={KeyboardDatePicker} label="Follow-Up Date" name="follow_up_date" />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field component={KeyboardDatePicker} label="Demo Date" name="demo_date" />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field component={KeyboardDatePicker} label="Commission Paid Date" name="comm_paid_date" />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={3}>
                        <Field component={KeyboardDatePicker} label="Closed Date" name="closed_date" />
                      </Grid>
                    }
                    {isAdmin &&
                      <Grid item xs={12}>
                        <Field
                          component={TextFieldFormik}
                          type="textarea"
                          label="Remarks"
                          name="comments"
                        />
                      </Grid>
                    }
                    {!isAdmin &&
                      <Grid item xs={12}>
                        <Field
                          component={TextFieldFormik}
                          type="textarea"
                          label="Remarks"
                          name="comments"
                          disabled
                        />
                      </Grid>
                    }
                    {(isAdmin || isSalesperson) &&
                      <Grid item xs={12} className="buttons-wrap">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Update
                        </Button>
                      </Grid>
                    }
                  </Grid>
                </Form>
              </MuiPickersUtilsProvider>
            )}
          </Formik>

          <Grid container className="payment-enquiry-pop-wrap">
            <Grid item xs={6} className="pdr-12">
              <Typography
                variant="subtitle"
                component="div"
                className="popup-label"
              >
                Customer Payments
              </Typography>

              <div className="tbl-minpad">
                <TblContainerPD>
                  <TblHeadPD />
                  <TableBody>
                    {paginSortingPD().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.serial_number}
                        </TableCell>
                        <TableCell>
                          {row.paid_amount}
                        </TableCell>
                        <TableCell>
                          {row.date_of_payment}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerPD>
                {(showPaginationPD == true) ? <TblPaginationPD /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
              {isAdmin &&
                <>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => setAddPaymentPopup(true)}
                    >
                      + Add Payment
                    </Button>
                  </div>

                  <Popup
                    openPopup={addPaymentPopup}
                    setOpenPopup={setAddPaymentPopup}
                    title={
                      <div className="popup-title-bar">
                        Add Customer Payment
                        <Button
                          onClick={() => {
                            setAddPaymentPopup(false)
                          }}
                          className="pop-close"
                        >
                          x
                        </Button>
                      </div>
                    }
                  >
                    <div>
                      <Formik
                        initialValues={initPaidAmountVal}
                        enableReinitialize
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            let token = localStorage.getItem("token");
                            //authentication
                            axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                              auth: token,
                            })
                              .then(auth => {
                                if (auth.data == true) {

                                  let currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

                                  if (values.date_of_payment != null) {
                                    if (typeof (values.date_of_payment) == "string") {
                                      values.date_of_payment = new Date(values.date_of_payment);
                                    }
                                    values.date_of_payment = format(values.date_of_payment, 'yyyy-MM-dd');
                                  }

                                  axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/payment/${enquiryId}/add`, {
                                    datetime_of_creation: currentDate,
                                    serial_number: values.serial_number,
                                    paid_amount: values.paid_amount,
                                    date_of_payment: values.date_of_payment,
                                  })
                                    .then(res => {
                                      setSubmitting(false);

                                      const selectedRowIndex = records.findIndex(record => record.id === enquiryId)
                                      const selectedRow = { ...records[selectedRowIndex], paidsum: parseInt(records[selectedRowIndex].paidsum) + parseInt(values.paid_amount) }

                                      setRecords(prev => {
                                        const updatedRecords = [...prev]
                                        updatedRecords[selectedRowIndex] = selectedRow
                                        return updatedRecords
                                      })


                                      showPopup(selectedRow)

                                      // setViewEditPopup(false);
                                      setAddPaymentPopup(false)
                                      setNotify({
                                        isOpen: true,
                                        message: `Payment has been successfully added.`,
                                        type: "success",
                                      });
                                    })
                                    .catch(error => {
                                      setSubmitting(false);
                                      setViewEditPopup(false);
                                      setNotify({
                                        isOpen: true,
                                        message: `Invalid Request.`,
                                        type: "error",
                                      });
                                      console.log(error)
                                    });

                                }
                                else {
                                  localStorage.clear();
                                  window.location.replace("https://partnerportal.bevootech.com/#/home");
                                }
                              })
                              .catch(error => {
                                console.log(error)
                              });

                            setSubmitting(false);
                          }, 500);
                        }}
                        //validation
                        validate={values => {
                          const errors: Partial<Values> = {};
                          if (!values.date_of_payment) {
                            errors.date_of_payment = 'Required';
                          }
                          if (!values.paid_amount) {
                            errors.paid_amount = 'Required';
                          }
                          if (!values.serial_number) {
                            errors.serial_number = 'Required';
                          }

                          return errors;
                        }}
                      >

                        {({ submitForm, isSubmitting }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Form>
                              {isSubmitting && <LinearProgress />}
                              <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <Field
                                    component={TextFieldFormik}
                                    type="text"
                                    label="Serial Number"
                                    name="serial_number"
                                  />
                                </Grid>
                                <Grid item xs={6} className="pdtp-0">
                                  <Field
                                    component={TextFieldFormik}
                                    type="text"
                                    label="Paid Amount"
                                    name="paid_amount"
                                  />
                                </Grid>
                                <Grid item xs={6} className="pdtp-0">
                                  <Field component={KeyboardDatePicker} label="Payment Date" name="date_of_payment" />
                                </Grid>
                                <Grid item xs={12} className="txt-right">
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          </MuiPickersUtilsProvider>
                        )}
                      </Formik>
                    </div>
                  </Popup>
                </>
              }
            </Grid>

            <Grid item xs={6} className="pdr-12">
              <Typography
                variant="subtitle"
                component="div"
                className="popup-label"
              >
                Commission Payments
              </Typography>

              <div className="tbl-minpad">
                <TblContainerCPD>
                  <TblHeadCPD />
                  <TableBody>
                    {paginSortingCPD().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.serial_number}
                        </TableCell>
                        <TableCell>
                          {row.commission_amount}
                        </TableCell>
                        <TableCell>
                          {row.date_of_payment}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerCPD>
                {(showPaginationCPD == true) ? <TblPaginationCPD /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
              {isAdmin &&
                <>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => setAddCommissionPaymentPopup(true)}
                    >
                      + Add Commission Payment
                    </Button>
                  </div>

                  <Popup
                    openPopup={addCommissionPaymentPopup}
                    setOpenPopup={setAddCommissionPaymentPopup}
                    title={
                      <div className="popup-title-bar">
                        Add Commission Payment
                        <Button
                          onClick={() => {
                            setAddCommissionPaymentPopup(false)
                          }}
                          className="pop-close"
                        >
                          x
                        </Button>
                      </div>
                    }
                  >
                    <div>
                      <Formik
                        initialValues={initCommissionPaidAmountVal}
                        enableReinitialize
                        onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                            let token = localStorage.getItem("token");
                            //authentication
                            axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                              auth: token,
                            })
                              .then(auth => {
                                if (auth.data == true) {

                                  let currentDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

                                  if (values.date_of_payment != null) {
                                    if (typeof (values.date_of_payment) == "string") {
                                      values.date_of_payment = new Date(values.date_of_payment);
                                    }
                                    values.date_of_payment = format(values.date_of_payment, 'yyyy-MM-dd');
                                  }

                                  axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/commission_payment/${enquiryId}/add`, {
                                    datetime_of_creation: currentDate,
                                    serial_number: values.serial_number,
                                    commission_amount: values.commission_amount,
                                    date_of_payment: values.date_of_payment,
                                  })
                                    .then(res => {
                                      setSubmitting(false);

                                      const selectedRowIndex = records.findIndex(record => record.id === enquiryId)
                                      const selectedRow = { ...records[selectedRowIndex], commission_sum: parseInt(records[selectedRowIndex].commission_sum) + parseInt(values.commission_amount) }

                                      setRecords(prev => {
                                        const updatedRecords = [...prev]
                                        updatedRecords[selectedRowIndex] = selectedRow
                                        return updatedRecords
                                      })


                                      showPopup(selectedRow)

                                      // setViewEditPopup(false);
                                      setAddCommissionPaymentPopup(false)
                                      setNotify({
                                        isOpen: true,
                                        message: `Commission has been successfully added.`,
                                        type: "success",
                                      });
                                    })
                                    .catch(error => {
                                      setSubmitting(false);
                                      setNotify({
                                        isOpen: true,
                                        message: `Invalid Request.`,
                                        type: "error",
                                      });
                                      console.log(error)
                                    });

                                }
                                else {
                                  localStorage.clear();
                                  window.location.replace("https://partnerportal.bevootech.com/#/home");
                                }
                              })
                              .catch(error => {
                                console.log(error)
                              });

                            setSubmitting(false);
                          }, 500);
                        }}
                        //validation
                        validate={values => {
                          const errors: Partial<Values> = {};
                          if (!values.date_of_payment) {
                            errors.date_of_payment = 'Required';
                          }
                          if (!values.commission_amount) {
                            errors.commission_amount = 'Required';
                          }
                          if (!values.serial_number) {
                            errors.serial_number = 'Required';
                          }

                          return errors;
                        }}
                      >

                        {({ submitForm, isSubmitting }) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Form>
                              {isSubmitting && <LinearProgress />}
                              <Grid container spacing={6}>
                                <Grid item xs={12}>
                                  <Field
                                    component={TextFieldFormik}
                                    type="text"
                                    label="Serial Number"
                                    name="serial_number"
                                  />
                                </Grid>
                                <Grid item xs={6} className="pdtp-0">
                                  <Field
                                    component={TextFieldFormik}
                                    type="text"
                                    label="Commission Amount"
                                    name="commission_amount"
                                  />
                                </Grid>
                                <Grid item xs={6} className="pdtp-0">
                                  <Field component={KeyboardDatePicker} label="Payment Date" name="date_of_payment" />
                                </Grid>
                                <Grid item xs={12} className="txt-right">
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                  >
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Form>
                          </MuiPickersUtilsProvider>
                        )}
                      </Formik>
                    </div>
                  </Popup>
                </>
              }
            </Grid>


          </Grid>

          <Grid container className="payment-enquiry-pop-wrap">
            <Grid item xs={12}>
              <Typography
                variant="subtitle"
                component="div"
                className="popup-label bg-secondary"
              >
                Enquiry Activities
              </Typography>

              <div className="tbl-minpad">
                <TblContainerEA>
                  <TableBody>
                    {paginSortingEA().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          <div className="content-wrap">
                            {row.title == "Partner Comments"
                              &&
                              <div className="partner-border"></div>
                            }

                            {(row.title == "Next Follow-Up Date" && row.created_by_user_level == "Admin")
                              &&
                              <div className="admin-border"></div>
                            }

                            {(row.title == "Next Follow-Up Date" && row.created_by_user_level == "Salesperson")
                              &&
                              <div className="salesperson-border"></div>
                            }


                            <div className="text-wrap">
                              <div className="title-wrap">
                                {row.title}
                                {(row.follow_up_date != null && row.follow_up_date != "0000-00-00" && row.follow_up_date != undefined && <span>&nbsp;(On {row.follow_up_date})</span>)}
                              </div>
                              <div className="comment-wrap">
                                {row.comment}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 150 }} align="right">
                          <div className='text-wrap'>
                            <div>{row.created_by_name}</div>
                            <div className="follow-up-wrap comment-wrap">
                              {row.datetime_of_creation}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerEA>
                {(showPaginationEA == true) ? <TblPaginationEA /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => setAddEnquiryActivityPopup(true)}
                >
                  + Add Comment
                </Button>
              </div>

              <Grid container className="partner-comments-wrap">
                <Popup
                  openPopup={addEnquiryActivityPopup}
                  setOpenPopup={setAddEnquiryActivityPopup}
                  title={
                    <div className="popup-title-bar">
                      <span style={{ textTransform: 'capitalize' }}>{currentUser} Comments</span>
                      <Button
                        type='button'
                        onClick={() => {
                          setAddEnquiryActivityPopup(false)
                        }}
                        className="pop-close"
                      >
                        x
                      </Button>
                    </div>
                  }
                >
                  <Grid item xs={12}>
                    <Formik
                      initialValues={initComVal}
                      enableReinitialize
                      onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {

                          let token = localStorage.getItem("token");
                          let username = localStorage.getItem("username");
                          let name = localStorage.getItem("name");

                          //authentication
                          axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                            auth: token,
                          })
                            .then(auth => {
                              if (auth.data == true) {

                                //follow up date comments
                                if (values.follow_up_date_comments != null) {
                                  if (typeof (values.follow_up_date_comments) == "string") {
                                    values.follow_up_date_comments = new Date(values.follow_up_date_comments);
                                  }
                                  values.follow_up_date_comments = format(values.follow_up_date_comments, 'yyyy-MM-dd');
                                }

                                axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry_activities/${enquiryId}/add`, {
                                  enquiry_id: { enquiryId },
                                  username: username,
                                  created_by: localStorage.getItem("user_id"),
                                  user_level: localStorage.getItem("user_level"),
                                  name: name,
                                  title: (isAdmin || isSalesperson) ? "Next Follow-Up Date" : "Partner Comments",
                                  comment: values.partner_comments,
                                  follow_up_date: (isAdmin || isSalesperson) ? values.follow_up_date_comments : "",
                                })
                                  .then(res => {
                                    if (res.data == 1) {
                                      const selectedRowIndex = records.findIndex(record => record.id === enquiryId)
                                      const selectedRow = records[selectedRowIndex]

                                      showPopup(selectedRow)

                                      setAddEnquiryActivityPopup(false)

                                      setSubmitting(false)

                                      setNotify({
                                        isOpen: true,
                                        message: `Comment has been successfully submmited.`,
                                        type: "success",
                                      });
                                    }
                                    else {
                                      setSubmitting(false);
                                      setNotify({
                                        isOpen: true,
                                        message: `Invalid Request.`,
                                        type: "error",
                                      });
                                    }
                                  })
                                  .catch(error => {
                                    setSubmitting(false);
                                    setNotify({
                                      isOpen: true,
                                      message: `Invalid Request.`,
                                      type: "error",
                                    });
                                    console.log(error);
                                  });


                              }
                              else {
                                localStorage.clear();
                                window.location.replace("/");
                              }
                            })
                            .catch(error => {
                              console.log(error)
                            });


                          // setViewEditPopup(false);

                        }, 500);
                      }}
                      //validation
                      validate={values => {
                        const errors: Partial<Values> = {};
                        if (!values.partner_comments) {
                          errors.partner_comments = 'Required';
                        }

                        return errors;
                      }}
                    >

                      {({ submitForm, isSubmitting }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Form className='viewEditForm'>
                            {isSubmitting && <LinearProgress />}

                            <Grid container spacing={6} className="mgbt-0">
                              {(isAdmin || isSalesperson) &&
                                <Grid item xs={6} className="follow-up-comments">
                                  <Field component={KeyboardDatePicker} label="Next Follow-Up Date" name="follow_up_date_comments" />
                                </Grid>
                              }
                              <Grid item xs={12}>
                                <Field
                                  component={TextFieldFormik}
                                  type="textarea"
                                  rows={2}
                                  multiline={true}
                                  name="partner_comments"
                                  placeholder="Remark"
                                />
                              </Grid>
                            </Grid>

                            <div className="buttons-wrap mgtp-25">
                              <Button
                                variant="contained"
                                color="primary"
                                className="mgr-10"
                                onClick={submitForm}
                              >
                                Submit
                              </Button>
                            </div>

                          </Form>
                        </MuiPickersUtilsProvider>
                      )}
                    </Formik>
                  </Grid>
                </Popup>
              </Grid>
            </Grid>
          </Grid>
        </Popup>

        <Paper elevation={0} square>
          <Typography
            variant="subtitle2"
            component="div"
            className="label"
          >
            Analytical Data
          </Typography>
          <div className="paper-root">
            <div className="icon-wrap">
              <Card className="page-icon" elevation={0}>
                <PeopleAltIcon />
              </Card>
              <div className="page-header">
                <Typography
                  variant="h6"
                  component="div"
                >
                  Total Enquiry
                </Typography>

                <Typography
                  variant="subtitle2"
                  component="span"
                >
                  {totalEnquiry}
                </Typography>
              </div>
            </div>

            <div className="icon-wrap">
              <Card className="page-icon" elevation={0}>
                <MonetizationOnIcon />
              </Card>
              <div className="page-header">
                <Typography
                  variant="h6"
                  component="div"
                >
                  Commission Amount
                </Typography>

                <Typography
                  variant="subtitle2"
                  component="span"
                >
                  <NumberFormat value={commissionAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </Typography>
              </div>
            </div>

            <div className="icon-wrap">
              <Card className="page-icon" elevation={0}>
                <AttachMoneyIcon />
              </Card>
              <div className="page-header">
                <Typography
                  variant="h6"
                  component="div"
                >
                  Sale Amount
                </Typography>

                <Typography
                  variant="subtitle2"
                  component="span"
                >
                  <NumberFormat value={saleAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </Typography>
              </div>
            </div>
          </div>
        </Paper>
        <div className="sec-wrap">
          <div className="search-filter-wrap">
            <Typography
              variant="subtitle2"
              component="div"
              className="label"
            >
              Enquiry Status
            </Typography>
            <Button variant="contained" color="primary" onClick={() => filterShow()} className="button-icon">
              <DateRangeIcon /> Filter Dates
            </Button>
          </div>
          {showFilter && <DateFilter />}
          <Paper className="tabs-root" elevation={0}>
            <Tabs value={valueTabs} onChange={handleChange} centered textColor="primary" indicatorColor="primary">
              <Tab label="Enquiries" {...a11yProps(0)} />
              <Tab label="Appointment" {...a11yProps(1)} />
              <Tab label="Follow Up" {...a11yProps(2)} />
              <Tab label="On-Hold" {...a11yProps(3)} />
              <Tab label="Closed" {...a11yProps(4)} />
              <Tab label="Lost" {...a11yProps(5)} />
            </Tabs>

            <TabPanel value={valueTabs} index={0}>
              <div className="search-field">
                <Toolbar>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                    }}
                    onChange={handleSearch}
                  />
                </Toolbar>
              </div>

              <div className="tbl-minpad enquiries">
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.datetime_of_referral}
                        </TableCell>
                        <TableCell>
                          {row.company_name}
                        </TableCell>
                        <TableCell>
                          {row.username}
                        </TableCell>
                        {/* {isAdmin &&
                        } */}
                        <TableCell>
                          {row.contact_name}
                        </TableCell>

                        <TableCell>
                          {row.mobile}
                        </TableCell>

                        <TableCell>
                          <div className={(row.status === "Lost") ? "status lost-stat" : (row.status === "On-Hold") ? "status on-hold-stat" : (row.status === "Appointment") ? "status appointment-stat" : (row.status === "Follow-Up") ? "status follow-up-stat" : (row.status === "Closed") ? "status closed-stat" : "status enquiry-stat"}>{row.status}</div>
                        </TableCell>

                        <TableCell>
                          <a className="view-details" onClick={() => showPopup(row)}><VisibilityIcon /></a>

                          {isAdmin
                            &&
                            <a className="delete-enquiry" onClick={() => {
                              setConfirmDelete({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this enquiry?",
                                onConfirm: () => {
                                  confirmDelEnquiry(row);
                                },
                              });
                            }}><DeleteIcon /></a>
                          }

                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainer>
                {(showPaginationE == false) ? <TblPagination /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
            </TabPanel>

            <TabPanel value={valueTabs} index={1}>
              <div className="search-field">
                <Toolbar>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                    }}
                    onChange={handleSearchAppointment}
                  />
                </Toolbar>
              </div>
              <div className="tbl-minpad enquiries">
                <TblContainerAppointment>
                  <TblHeadAppointment />
                  <TableBody>
                    {paginSortingAppointment().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.datetime_of_referral}
                        </TableCell>
                        <TableCell>
                          {row.company_name}
                        </TableCell>
                        {isAdmin &&
                          <TableCell>
                            {row.username}
                          </TableCell>
                        }
                        <TableCell>
                          {row.contact_name}
                        </TableCell>
                        <TableCell>
                          {row.mobile}
                        </TableCell>
                        <TableCell>
                          {row.demo_date}
                        </TableCell>
                        <TableCell>
                          <a className="view-details" onClick={() => showPopup(row)}><VisibilityIcon /></a>

                          {isAdmin &&
                            <a className="delete-enquiry" onClick={() => {
                              setConfirmDelete({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this enquiry?",
                                onConfirm: () => {
                                  confirmDelEnquiry(row);
                                },
                              });
                            }}><DeleteIcon /></a>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerAppointment>
                {(showPaginationA == false) ? <TblPaginationAppointment /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
            </TabPanel>

            <TabPanel value={valueTabs} index={2}>
              <div className="search-field">
                <Toolbar>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                    }}
                    onChange={handleSearchFollowUp}
                  />
                </Toolbar>
              </div>
              <div className="tbl-minpad enquiries">
                <TblContainerFollowUp>
                  <TblHeadFollowUp />
                  <TableBody>
                    {paginSortingFollowUp().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.datetime_of_referral}
                        </TableCell>
                        <TableCell>
                          {row.company_name}
                        </TableCell>
                        {isAdmin &&
                          <TableCell>
                            {row.username}
                          </TableCell>
                        }
                        <TableCell>
                          {row.contact_name}
                        </TableCell>
                        <TableCell>
                          {row.mobile}
                        </TableCell>
                        <TableCell>
                          {row.follow_up_date}
                        </TableCell>
                        <TableCell>
                          <a className="view-details" onClick={() => showPopup(row)}><VisibilityIcon /></a>

                          {isAdmin &&
                            <a className="delete-enquiry" onClick={() => {
                              setConfirmDelete({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this enquiry?",
                                onConfirm: () => {
                                  confirmDelEnquiry(row);
                                },
                              });
                            }}><DeleteIcon /></a>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerFollowUp>
                {(showPaginationF == false) ? <TblPaginationFollowUp /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
            </TabPanel>

            <TabPanel value={valueTabs} index={3}>
              <div className="search-field">
                <Toolbar>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                    }}
                    onChange={handleSearchOnHold}
                  />
                </Toolbar>
              </div>
              <div className="tbl-minpad enquiries">
                <TblContainerOnHold>
                  <TblHeadOnHold />
                  <TableBody>
                    {paginSortingOnHold().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.datetime_of_referral}
                        </TableCell>
                        <TableCell>
                          {row.company_name}
                        </TableCell>
                        {isAdmin &&
                          <TableCell>
                            {row.username}
                          </TableCell>
                        }
                        <TableCell>
                          {row.contact_name}
                        </TableCell>
                        <TableCell>
                          {row.mobile}
                        </TableCell>
                        <TableCell>
                          <NumberFormat value={row.sales_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell>
                        <TableCell>
                          <a className="view-details" onClick={() => showPopup(row)}><VisibilityIcon /></a>

                          {isAdmin &&
                            <a className="delete-enquiry" onClick={() => {
                              setConfirmDelete({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this enquiry?",
                                onConfirm: () => {
                                  confirmDelEnquiry(row);
                                },
                              });
                            }}><DeleteIcon /></a>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerOnHold>
                {(showPaginationO == false) ? <TblPaginationOnHold /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
            </TabPanel>

            <TabPanel value={valueTabs} index={4}>
              <div className="search-field">
                <Toolbar>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                    }}
                    onChange={handleSearchClosed}
                  />
                </Toolbar>
              </div>
              <div className="tbl-minpad enquiries">
                <TblContainerClosed>
                  <TblHeadClosed />
                  <TableBody>
                    {paginSortingClosed().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.datetime_of_referral}
                        </TableCell>
                        <TableCell>
                          {row.company_name}
                        </TableCell>
                        {isAdmin &&
                          <TableCell>
                            {row.username}
                          </TableCell>
                        }
                        <TableCell>
                          {row.contact_name}
                        </TableCell>
                        <TableCell>
                          {row.mobile}
                        </TableCell>
                        <TableCell>
                          {row.closed_date}
                        </TableCell>
                        <TableCell>
                          <NumberFormat value={row.sales_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell>
                        <TableCell>
                          <NumberFormat value={row.payment_received} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell>
                        <TableCell>
                          <NumberFormat value={row.commission_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell>

                        <TableCell>
                          <a className="view-details" onClick={() => showPopup(row)}><VisibilityIcon /></a>

                          {isAdmin &&
                            <a className="delete-enquiry" onClick={() => {
                              setConfirmDelete({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this enquiry?",
                                onConfirm: () => {
                                  confirmDelEnquiry(row);
                                },
                              });
                            }}><DeleteIcon /></a>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerClosed>
                {(showPaginationC == false) ? <TblPaginationClosed /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
            </TabPanel>

            <TabPanel value={valueTabs} index={5}>
              <div className="search-field">
                <Toolbar>
                  <TextField
                    label="Search Company"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                    }}
                    onChange={handleSearchLost}
                  />
                </Toolbar>
              </div>
              <div className="tbl-minpad enquiries">
                <TblContainerLost>
                  <TblHeadLost />
                  <TableBody>
                    {paginSortingLost().map((row) => (
                      <TableRow key={row.name}>
                        <TableCell component="th" scope="row">
                          {row.datetime_of_referral}
                        </TableCell>
                        <TableCell>
                          {row.company_name}
                        </TableCell>
                        {isAdmin &&
                          <TableCell>
                            {row.username}
                          </TableCell>
                        }
                        <TableCell>
                          {row.contact_name}
                        </TableCell>
                        <TableCell>
                          {row.mobile}
                        </TableCell>
                        <TableCell>
                          <NumberFormat value={row.sales_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                        </TableCell>
                        <TableCell>
                          <a className="view-details" onClick={() => showPopup(row)}><VisibilityIcon /></a>

                          {isAdmin &&
                            <a className="delete-enquiry" onClick={() => {
                              setConfirmDelete({
                                isOpen: true,
                                title:
                                  "Are you sure you want to delete this enquiry?",
                                onConfirm: () => {
                                  confirmDelEnquiry(row);
                                },
                              });
                            }}><DeleteIcon /></a>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </TblContainerLost>
                {(showPaginationL == false) ? <TblPaginationLost /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
              </div>
            </TabPanel>

          </Paper>
        </div>
      </div>
    </React.Fragment >
  );
}
