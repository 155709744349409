import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PeopleIcon from '@material-ui/icons/People';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import HomeIcon from '@material-ui/icons/Home';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { format } from "date-fns";
import { TextField as TextFieldFormik } from 'formik-material-ui';
import ClickAwayListener from 'react-click-away-listener';
import { useFileUpload } from 'use-file-upload'
import {
  makeStyles,
  useTheme,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  IconButton,
  Button,
  ListItemText,
  ListItemIcon,
  ListItem,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Grid
} from '@material-ui/core';

import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';

//pages
import Home from '../Pages/Home';
import Enquiry from '../Pages/Enquiry';
import MyCommission from '../Pages/MyCommission';
import Partner from '../Pages/Partner';
import Salesperson from '../Pages/Salesperson';
import Documents from '../Pages/Documents';

//components
import ConfirmDialog from "./ConfirmDialog";
import Popup from '../components/Popup';
import Notification from "./Notification";
import Nav from '../components/Nav';
import ManageAdmin from '../Pages/ManageAdmin';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  defaultLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  logoWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: '2'
  }
}));


export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [profileSec, setProfileSec] = useState(false);
  const [profilePopup, setProfilePopup] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [addPhotoNotify, setPhotoAddNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  //form initial values
  const [initVal, setInitVal] = useState({
    username: "",
    email: "",
    name: "",
    company_name: "",
    mobile: "",
    address: "",
    password: "",
  });

  //upload picture 
  const [defaultPic, setDefaultPic] = useState("");
  const [existingPhoto, setExistingPhoto] = useState(false);
  const [files, selectFiles] = useFileUpload();
  const [showUpload, setShowUpload] = useState(false);
  const [photoBase64, setPhotoBase64] = useState("");
  const [photoPopup, setPhotoPopup] = useState(false);
  const [exceedUpload, setExceedUpload] = useState(false);
  const [photoSubmitting, setPhotoSubmitting] = useState(false);
  const [userType, setUserType] = useState("")
  const [userLevel, setUserLevel] = useState("")

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //toggle password
  const togglePwd = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordVisibility(true);
    }
    else {
      setPasswordType("password");
      setPasswordVisibility(false);
    }
  }

  //logout
  const handleLogout = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });

    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/logout`, {
            token: token,
          })
            .then(res => {
              if (res.data == true) {

                localStorage.clear();
                window.location.replace("/");
              }
            })
            .catch(error => {
              console.log(error)
            });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  };

  //menu items
  const menuItems = [
    { text: "Dashboard", icon: <HomeIcon />, element: <Home />, path: "/home", access: ["Admin", "Partner", "Salesperson", "Root"] },
    { text: "Enquiry", icon: <CreateIcon />, element: <Enquiry />, path: "/enquiry", access: ["Admin", "Partner", "Salesperson"] },
    { text: "Commissions", icon: <MonetizationOnIcon />, element: <MyCommission />, path: "/my-commission", access: ["Partner"] },
    { text: "Documents", icon: <DescriptionIcon />, element: <Documents />, path: "/documents", access: ["Admin", "Partner"] },
    { text: "Partner", icon: <PeopleIcon />, element: <Partner />, path: "/partner", access: ["Admin"] },
    { text: "Salesperson", icon: <AssignmentIndIcon />, element: <Salesperson />, path: "/salesperson", access: ["Admin"] },
    // { text: "Admin", icon: <PeopleIcon />, element: <Partner />, path: "/admin", access: ["Root"] },

  ]

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user_id = localStorage.getItem("user_id");
    let user_photo = localStorage.getItem("user_photo");

    let user_type = localStorage.getItem("user_type");
    setUserType(user_type)

    let user_level = localStorage.getItem("user_level");
    setUserLevel(user_level)

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //use data
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/${user_id}`).then((res) => {
            localStorage.setItem("name", res.data[0].name);
            localStorage.setItem("email", res.data[0].email);
            localStorage.setItem("company_name", res.data[0].company_name);
            localStorage.setItem("mobile", res.data[0].mobile);
            localStorage.setItem("address", res.data[0].address);
          });

          let userPhotoCheck = user_photo.substring(0, 4);

          if (userPhotoCheck == "data") {
            setExistingPhoto(true);
            setDefaultPic(user_photo);
          }
          else {
            setDefaultPic("/imgs/profile-pic-lg.jpg");
            setExistingPhoto(false);
          }

        }
      })
      .catch(error => {
        console.log(error)
      });

  }, []);

  //toggle profile section
  const toggleProfile = () => {
    setProfileSec(!profileSec);
  };

  const handleProfileClickAway = () => {
    setProfileSec(!profileSec);
  };

  const ProfileData = () => {
    return (
      <ClickAwayListener onClickAway={handleProfileClickAway}>
        <Card className="profile-wrap" elevation={3}>
          <CardContent>
            <div className="header-content">
              <img src={localStorage.getItem("user_photo")} alt="Profile Pic" className="profile-pic-lg" />
              <div className="uploadPhoto" onClick={() => { photoUpdatePopup(); }}>
                <AddAPhotoIcon />
              </div>
              <div className="info">
                <Typography variant="h6">
                  {localStorage.getItem("name")}
                </Typography>
                <Typography variant="subtitle2">
                  {localStorage.getItem("email")}
                </Typography>
              </div>
            </div>
            <div className="body-content">
              <Typography variant="subtitle2" className="label">
                Company Name
              </Typography>
              <Typography variant="subtitle2" className="mgbt-10">
                {localStorage.getItem("company_name")}
              </Typography>
              <Typography variant="subtitle2" className="label">
                Phone Number
              </Typography>
              <Typography variant="subtitle2" className="mgbt-10">
                {localStorage.getItem("mobile")}
              </Typography>
              <Typography variant="subtitle2" className="label">
                Address
              </Typography>
              <Typography variant="subtitle2" className="mgbt-10">
                {localStorage.getItem("address")}
              </Typography>
            </div>
          </CardContent>
          <CardActions>
            <div>
              <Button size="small" color="primary" variant="contained" onClick={() => { openProfilePopup(); }}>Update</Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title:
                      "Are you sure you want to logout?",
                    onConfirm: () => {
                      handleLogout();
                    },
                  });
                }}
              >
                Logout
              </Button>
            </div>
          </CardActions>
        </Card>
      </ClickAwayListener>
    )
  }

  //open photo popup
  const photoUpdatePopup = () => {
    let user_id = localStorage.getItem("user_id");
    let user_photo = localStorage.getItem("user_photo");

    let userPhotoCheck = user_photo.substring(0, 4);

    if (userPhotoCheck == "data") {
      setExistingPhoto(true);
      setDefaultPic(user_photo);
    }
    else {
      setDefaultPic("/imgs/profile-pic-lg.jpg");
      setExistingPhoto(false);
    }

    setPhotoPopup(true);
  }


  //open profile popup
  const openProfilePopup = () => {
    setProfileSec(false);
    setProfilePopup(true);

    let user_id = localStorage.getItem("user_id");
    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/${user_id}`).then((res) => {

            //variables
            let username = res.data[0].username;
            let email = res.data[0].email;
            let name = res.data[0].name;
            let company_name = res.data[0].company_name;
            let mobile = res.data[0].mobile;
            let address = res.data[0].address;
            let password = res.data[0].password;
            let user_level = res.data[0].user_level;

            //check user level
            if (user_level == "Admin") {
              setIsAdmin(true);
            }

            //fetch db data
            setInitVal({
              username: username,
              email: email,
              name: name,
              company_name: company_name,
              mobile: mobile,
              address: address,
              password: password,
            });

          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  };

  //close view edit popup
  const closeProfilePopup = () => {
    setProfilePopup(false);
  };

  //close photo popup
  const closePhotoPopup = () => {
    setPhotoPopup(false);
    setShowUpload(false);
    //remove selected photo
    if (files) {
      files.source = "";
    }
    setExceedUpload(false);
  };

  //select photo
  const selectFile = () => {
    selectFiles({ accept: "image/*" }, ({ name, size, source, file }) => {
      // Make new FileReader
      let reader = new FileReader();
      let genBase64 = "";

      if (name != null) {
        if (size > 200000) {
          setExceedUpload(true);
          setShowUpload(false);
        }
        else {
          setExceedUpload(false);
          // Convert the file to base64 text
          reader.readAsDataURL(file);

          reader.onload = () => {
            genBase64 = reader.result;
            setPhotoBase64(genBase64);
          };
          setShowUpload(true);
        }
      }
    })
  };

  //upload photo
  const uploadPhoto = () => {
    setPhotoSubmitting(true);
    let user_id = localStorage.getItem("user_id");
    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {

          if (existingPhoto == true) {

            axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/userphoto/update/${user_id}`, {
              base64Img: photoBase64,
            })
              .then(res => {
                if (res.data == 1) {
                  localStorage.setItem("user_photo", photoBase64);
                  setPhotoSubmitting(false);
                  setPhotoPopup(false);
                  setShowUpload(false);
                  //remove selected photo
                  if (files) {
                    files.source = "";
                  }
                  setExceedUpload(false);
                  setPhotoAddNotify({
                    isOpen: true,
                    message: `Your photo has been successfully updated.`,
                    type: "success",
                  });
                }
                else {
                  setPhotoSubmitting(false);
                  setPhotoPopup(false);
                  setShowUpload(false);
                  //remove selected photo
                  if (files) {
                    files.source = "";
                  }
                  setExceedUpload(false);
                  setPhotoAddNotify({
                    isOpen: true,
                    message: `Invalid Request.`,
                    type: "error",
                  });
                }
              })
              .catch(error => {
                setPhotoSubmitting(false);
                setPhotoPopup(false);
                setShowUpload(false);
                //remove selected photo
                if (files) {
                  files.source = "";
                }
                setExceedUpload(false);
                setPhotoAddNotify({
                  isOpen: true,
                  message: `Invalid Request.`,
                  type: "error",
                });
                console.log(error);
              });

          }
          else {
            axios.post("${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/userphoto/add", {
              user_id: user_id,
              base64Img: photoBase64
            })
              .then(res => {
                if (res.data == 1) {
                  localStorage.setItem("user_photo", photoBase64);
                  setPhotoSubmitting(false);
                  setPhotoPopup(false);
                  setShowUpload(false);
                  //remove selected photo
                  if (files) {
                    files.source = "";
                  }
                  setExceedUpload(false);
                  setPhotoAddNotify({
                    isOpen: true,
                    message: `Your photo has been successfully uploaded.`,
                    type: "success",
                  });


                }
                else {
                  setPhotoSubmitting(false);
                  setPhotoPopup(false);
                  setShowUpload(false);
                  //remove selected photo
                  if (files) {
                    files.source = "";
                  }
                  setExceedUpload(false);
                  setPhotoAddNotify({
                    isOpen: true,
                    message: `Invalid Request.`,
                    type: "error",
                  });
                  console.log(res);
                }

              })
              .catch(error => {
                setPhotoSubmitting(false);
                setPhotoPopup(false);
                setShowUpload(false);
                //remove selected photo
                if (files) {
                  files.source = "";
                }
                setExceedUpload(false);
                setPhotoAddNotify({
                  isOpen: true,
                  message: `Invalid Request.`,
                  type: "error",
                });
                console.log(error);
              });
          }

        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Notification notify={notify} setNotify={setNotify} />
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        <Notification notify={addPhotoNotify} setNotify={setPhotoAddNotify} />
        <Popup
          openPopup={photoPopup}
          setOpenPopup={setPhotoPopup}
          title={
            <div className="popup-title-bar">
              Update Photo
              <Button
                onClick={() => {
                  closePhotoPopup();
                }}
                className="pop-close"
              >
                x
              </Button>
            </div>
          }
        >

          <div className="profilePicWrap">
            {photoSubmitting && <LinearProgress />}
            <p><strong>Note:</strong> Please upload maximum 200kb size</p>
            {exceedUpload && <span className="exceed-upload">Your image exceeds the size limit</span>}
            <img src={files?.source || defaultPic} alt="preview" onClick={() => selectFile()} />
            <Button variant="contained" size="small" onClick={() => selectFile()}>Select</Button> &nbsp;
            {showUpload &&
              <Button variant="contained" color="primary" size="small" onClick={() => uploadPhoto()}>Upload</Button>
            }
          </div>

        </Popup>
        <Popup
          openPopup={profilePopup}
          setOpenPopup={setProfilePopup}
          title={
            <div className="popup-title-bar">
              PROFILE INFORMATION
              <Button
                onClick={() => {
                  closeProfilePopup();
                }}
                className="pop-close"
              >
                x
              </Button>
            </div>
          }
        >

          <Formik
            initialValues={initVal}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {

                let token = localStorage.getItem("token");
                let user_id = localStorage.getItem("user_id");
                let user_level = localStorage.getItem("user_level");

                //authentication
                axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                  auth: token,
                })
                  .then(auth => {

                    const newDate = new Date();
                    const modificate_date = format(newDate, 'yyyy-MM-dd HH:mm:ss');

                    axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/update/${user_id}`, {
                      username: values.username,
                      user_level: user_level,
                      datetime_of_modification: modificate_date,
                      email: values.email,
                      name: values.name,
                      company_name: values.company_name,
                      mobile: values.mobile,
                      address: values.address,
                      password: values.password_new,
                    })
                      .then(function (response) {
                        setProfilePopup(false);

                        if (response.data == true) {

                          localStorage.setItem("name", values.name);
                          localStorage.setItem("email", values.email);
                          localStorage.setItem("company_name", values.company_name);
                          localStorage.setItem("mobile", values.mobile);
                          localStorage.setItem("address", values.address);

                          setNotify({
                            isOpen: true,
                            message: `${values.username} has been successfully updated.`,
                            type: "success",
                          });
                        }
                        else {
                          setNotify({
                            isOpen: true,
                            message: `Invalid Request.`,
                            type: "error",
                          });
                        }

                      });
                  })
                  .catch(error => {
                    console.log(error)
                  });


                closeProfilePopup(false);
                setSubmitting(false);

              }, 500);
            }}
            //validation
            validate={values => {
              const errors: Partial<Values> = {};

              if (!values.username) {
                errors.username = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (!values.name) {
                errors.name = 'Required';
              }
              // if (!values.company_name) {
              //   errors.company_name = 'Required';
              // }
              // if (!values.mobile) {
              //   errors.mobile = 'Required';
              // }
              // if (!values.address) {
              //   errors.address = 'Required';
              // }

              return errors;
            }}
          >

            {({ submitForm, isSubmitting }) => (
              <Form>
                {isSubmitting && <LinearProgress />}

                <Grid container spacing={6} className="mgbt-0">
                  {isAdmin &&
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Username"
                        name="username"
                      />
                    </Grid>
                  }
                  {!isAdmin &&
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Username"
                        name="username"
                        disabled
                      />
                    </Grid>
                  }
                  {isAdmin &&
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Email"
                        name="email"
                      />
                    </Grid>
                  }
                  {!isAdmin &&
                    <Grid item xs={4}>
                      <Field
                        component={TextFieldFormik}
                        type="text"
                        label="Email"
                        name="email"
                        disabled
                      />
                    </Grid>
                  }
                  <Grid item xs={4}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Name"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Company Name"
                      name="company_name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Phone Number"
                      name="mobile"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Address"
                      name="address"
                    />
                  </Grid>
                  <Grid item xs={4} className="password-wrap header">
                    <Field
                      component={TextFieldFormik}
                      type={passwordType}
                      label="Password"
                      name="password_new"
                    />
                    <div className="toggle-password" onClick={() => togglePwd()}>
                      {passwordVisibility && <VisibilityIcon />}
                      {!passwordVisibility && <VisibilityOffIcon className="visibility-off" />}
                    </div>
                  </Grid>
                </Grid>

                <div className="buttons-wrap">
                  <Button
                    variant="contained"
                    onClick={() => closeProfilePopup(false)}
                    className="mgr-10"
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Update
                  </Button>
                </div>

              </Form>
            )}
          </Formik>

        </Popup>
        <CssBaseline />
        {/* {alert(["Salesperson", "Admin", "Partner"].includes("Admin"))} */}
        <Router>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar>
              <div className={classes.logoWrap}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon className="burger-icon" />
                </IconButton>
                <Typography variant="h6" className={classes.title} >
                  <img src="/imgs/bevootech.svg" alt="Profile Pic" className="logo" />
                </Typography>
              </div>

              <div className="account-wrap">

                <img src={localStorage.getItem("user_photo")} alt="Profile Pic" onClick={() => { toggleProfile(); }} className="profile-pic-sm" />

                <div className="sub-links-wrap">
                  <IconButton onClick={() => { toggleProfile(); }} >
                    <ExpandMoreIcon />
                  </IconButton>
                  {profileSec && <ProfileData />}

                </div>
              </div>
            </Toolbar>
          </AppBar>

          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >

            <div className="close-drawer-wrap">
              <IconButton onClick={handleDrawerClose}>
                {<ChevronLeftIcon />}
              </IconButton>
            </div>

            <List>
              {menuItems.map((item) => {
                const { text, icon, path } = item;
                return (
                  item.access.includes(userLevel)
                  &&
                  < Link to={path} className={classes.defaultLink} >
                    <ListItem button key={text}>
                      {icon && <ListItemIcon>{icon}</ListItemIcon>}
                      <ListItemText primary={text} />
                    </ListItem>
                  </Link>
                );
              })}

            </List>
          </Drawer>

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            <Switch>
              {userLevel === "Root"
                ?
                <Route path='/home' exact component={ManageAdmin} />
                :
                <Route path='/home' exact component={Home} />
              }
              <Route path='/enquiry' component={Enquiry} />
              <Route path='/my-commission' component={MyCommission} />
              <Route path='/documents' component={Documents} />
              <Route path='/partner' component={Partner} />
              <Route path='/salesperson' component={Salesperson} />
            </Switch>
          </main>
        </Router>

      </div>
    </React.Fragment >
  );
}
