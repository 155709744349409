import React, { useState, useEffect } from 'react';
import Pages from '../components/Pages';
import {
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import IdleTimerContainer from '../components/IdleTimerContainer';
import Login from '../Pages/Login';

//override theme styles
const theme = createMuiTheme({
  palette:{
    background:{
      default: '#e4e4e4'
    }
  }
})

export default function App() {
  const [idleState, setIdleState] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const domainUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_DOMAIN_URL_PROD : process.env.REACT_APP_DOMAIN_URL;
  const homeUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_HOME_URL_PROD : process.env.REACT_APP_HOME_URL;


  const pageTokenCheck = () => {
    let token = localStorage.getItem("token");
      if(!token){
        document.body.classList.add("login-page");
        window.location.replace(`${domainUrl}`)
    }
  }

  const loginTokenCheck = () => {
    let token = localStorage.getItem("token");
      if(token){
        setIsLogin(true);
        setIdleState(true);
        document.body.classList.remove("login-page");
        window.location.replace(`${homeUrl}`);
    }
  }

  useEffect(() => {
    loginTokenCheck();
    pageTokenCheck();
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      {!isLogin && <Login />}
      {isLogin && <Pages />}
      {idleState ? <IdleTimerContainer /> : null}
    </ThemeProvider>
  );
}

