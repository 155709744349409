import React from 'react';
import {
  Paper,
  Card,
  Typography,
  makeStyles
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#faffcb',
		alignItems: 'center',
		display: 'flex',
		marginBottom: '30px',
		padding: '20px'  
	},
	pageHeader:{
		paddingLeft: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column'
	}
}));

export default function(props){
	const classes = useStyles();
	const {title,subTitle,icon} = props;

	return(
		<Paper elevation={0} square className={classes.root}>
			<Card className="page-icon">
				{icon}
			</Card>
			<div className={classes.pageHeader}>
				<Typography 
					variant = "h6"
					component = "div"
				>
				{title}
				</Typography>

				<Typography 
					variant = "subtitle2"
					component = "div"
				>
				{subTitle}
				</Typography>
			</div>
		</Paper>
	)
}