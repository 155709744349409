import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import SearchIcon from '@material-ui/icons/Search';
import PeopleIcon from '@material-ui/icons/People';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { format } from "date-fns";
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  Toolbar,
  TextField,
  Select,
  LinearProgress,
  Grid,
  Typography,
  MenuItem
} from '@material-ui/core';

//components
import AllRecordsTbl from '../components/AllRecordsTbl';
import ConfirmDialog from "../components/ConfirmDialog";
import Notification from "../components/Notification";
import Popup from '../components/Popup';

//table head cells
const headCells = [
  { id: 'username', label: 'Username' },
  { id: 'status', label: 'Status' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'company', label: 'Company' },
  { id: 'mobile', label: 'Mobile' },
  { id: 'address', label: 'Address' },
  { id: 'salesperson_name', label: 'Assigned Salesperson' },
  { id: 'actions', label: 'Actions' },
]

export default function Partner() {
  let [records, setRecords] = useState([]);
  const [viewEditPopup, setViewEditPopup] = useState(false);
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [userId, setUserId] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [rangesSalesperson, setRangesSalesperson] = useState([]);

  const [userStatusFilter, setUserStatusFilter] = useState(0)

  const [addNotify, setAddNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [updateNotify, setUpdateNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [deleteNotify, setDeleteNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [showFilter, setShowFilter] = useState(false); const {
    TblContainer,
    TblPagination,
    TblHead,
    recordsAfterPagingAndSorting
  } = AllRecordsTbl(records, headCells, filterFn);
  //pagination
  const [showPaginationE, setShowPaginationE] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState({
    isOpen: false,
    title: "",
  });
  //form edit initial values
  const [initVal, setInitVal] = useState({
    username: "",
    user_level: "",
    email: "",
    name: "",
    company_name: "",
    mobile: "",
    address: "",
    password: ""
  });
  //form add initial values
  const [initAddUserVal, setInitAddUserVal] = useState({
    username: "",
    user_level: "",
    email: "",
    name: "",
    company_name: "",
    mobile: "",
    address: "",
    password: ""
  });

  //enquiry status
  const [rangesUserLevel, setRangesUserLevel] = useState([
    {
      value: 'Admin',
      label: 'Admin',
    },
    {
      value: 'Partner',
      label: 'Partner',
    }
  ]);

  //search
  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.username.toLowerCase().includes(target.value))
        }
      }
    })
  }

  const pageTokenCheck = () => {
    let token = localStorage.getItem("token");
    if (!token) {
      document.body.classList.add("login-page");
      window.location.replace("https://partnerportal.bevootech.com/#/");
    }
  }

  useEffect(() => {
    pageTokenCheck();

    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //fetch list of users
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/partners`).then((res) => {
            const responseRecords = res.data;
            setRecords(responseRecords);

            let countE = Object.keys(responseRecords).length;
            if (countE > 0) {
              setShowPaginationE(false);
            }
            else {
              setShowPaginationE(true);
            }
          });

          //fetch list of salespersons list
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/salespersons`).then((resSalesperson) => {
            setRangesSalesperson(resSalesperson.data)
          });

        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }, []);

  //confirm delete enquiry
  const confirmDelEnquiry = (row) => {
    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //delete enquiry based on the enquiry id
          axios.delete(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/delete/${row.user_id}`).then((resDelete) => {
            if (resDelete.data === 1) {

              //fetch list of partners
              axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/partners`).then((res) => {
                const responseRecords = res.data;
                setRecords(responseRecords);

                let countE = Object.keys(responseRecords).length;
                if (countE > 0) {
                  setShowPaginationE(false);
                }
                else {
                  setShowPaginationE(true);
                }
              });
              setConfirmDelete({
                ...confirmDelete,
                isOpen: false,
              });
              setDeleteNotify({
                isOpen: true,
                message: `Successfully deleted the user.`,
                type: "success",
              });
            }
            else {
              setConfirmDelete({
                ...confirmDelete,
                isOpen: false,
              });
              setDeleteNotify({
                isOpen: true,
                message: `Unable to process your request`,
                type: "error",
              });
            }
          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  };

  //add user
  const addUser = () => {
    setAddUserPopup(true);
  }

  //toggle password
  const togglePwd = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      setPasswordVisibility(true);
    }
    else {
      setPasswordType("password");
      setPasswordVisibility(false);
    }
  }

  //show view edit popup
  const showDetailsPopup = (row) => {
    setViewEditPopup(true);
    setInitVal(row);
    setUserId(row.user_id);

  };

  //close view edit popup
  const closeViewEditPopup = () => {
    setViewEditPopup(false);
  };

  //close add user popup
  const closeAddUserPopup = () => {
    setAddUserPopup(false);
  };

  return (
    <div className="partner-form">
      <div className="container">
        <ConfirmDialog
          confirmDialog={confirmDelete}
          setConfirmDialog={setConfirmDelete}
        />
        <Notification notify={addNotify} setNotify={setAddNotify} />
        <Notification notify={updateNotify} setNotify={setUpdateNotify} />
        <Notification notify={deleteNotify} setNotify={setDeleteNotify} />

        <Popup
          openPopup={addUserPopup}
          setOpenPopup={setAddUserPopup}
          title={
            <div className="popup-title-bar">
              Add New Partner
              <Button
                onClick={() => {
                  closeAddUserPopup();
                }}
                className="pop-close"
              >
                x
              </Button>
            </div>
          }
        >
          <Formik
            initialValues={initAddUserVal}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {

                let token = localStorage.getItem("token");

                //authentication
                axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                  auth: token,
                })
                  .then(auth => {
                    if (auth.data == true) {
                      let modifacationDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
                      axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/add`, {
                        username: values.username,
                        user_level: 'Partner',
                        salesperson_id: values.salesperson_id,
                        datetime_of_creation: modifacationDate,
                        datetime_of_modification: "",
                        email: values.email,
                        name: values.name,
                        company_name: values.company_name,
                        mobile: values.mobile,
                        address: values.address,
                        password: values.password,
                        status: values.status,
                      })
                        .then(res => {
                          if (res.data == 1) {
                            //fetch list of users
                            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/partners`).then((res) => {
                              const responseRecords = res.data;
                              setRecords(responseRecords);

                              let countE = Object.keys(responseRecords).length;
                              if (countE > 0) {
                                setShowPaginationE(false);
                              }
                              else {
                                setShowPaginationE(true);
                              }
                            });

                            //fetch list of salespersons list
                            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/salespersons`).then((resSalesperson) => {
                              setRangesSalesperson(resSalesperson.data)
                            });

                            setSubmitting(false);
                            setAddUserPopup(false);
                            setAddNotify({
                              isOpen: true,
                              message: `The partner has been created successfully.`,
                              type: "success",
                            });
                          }
                          else {
                            console.log(res.data);
                            setSubmitting(false);
                            // setAddUserPopup(false);
                            setAddNotify({
                              isOpen: true,
                              message: `The username already exists`,
                              type: "error",
                            });
                          }

                        })
                        .catch(error => {
                          setSubmitting(false);
                          // setAddUserPopup(false);
                          setAddNotify({
                            isOpen: true,
                            message: `Invalid Request.`,
                            type: "error",
                          });
                          console.log(error)
                        });
                    }
                    else {
                      localStorage.clear();
                      window.location.replace("/");
                    }
                  })
                  .catch(error => {
                    setAddUserPopup(false);
                    setSubmitting(false);
                    console.log(error)
                  });
              }, 500);
            }}
            //validation
            validate={values => {
              const errors: Partial<Values> = {};
              if (!values.username) {
                errors.username = 'Required';
              }
              // if (!values.user_level) {
              //   errors.user_level = 'Required';
              // }
              if (!values.name) {
                errors.name = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              // if (!values.company_name) {
              //   errors.company_name = 'Required';
              // }
              // if (!values.mobile) {
              //   errors.mobile = 'Required';
              // }
              // if (!values.address) {
              //   errors.address = 'Required';
              // }
              if (!values.password) {
                errors.password = 'Required';
              }
              if (!values.salesperson_id) {
                errors.salesperson_id = 'Required';
              }

              return errors;
            }}
          >

            {({ submitForm, isSubmitting }) => (
              <Form className='viewEditForm'>
                {isSubmitting && <LinearProgress />}

                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Username"
                      name="username"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      select
                      name="salesperson_id"
                      label="Assign Salesperson"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {rangesSalesperson.map((option) => (
                        <MenuItem key={option.user_id} value={option.user_id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Name"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Email"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Company Name"
                      name="company_name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Mobile"
                      name="mobile"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Address"
                      name="address"
                    />
                  </Grid>
                  <Grid item xs={3} className="password-wrap">
                    <Field
                      component={TextFieldFormik}
                      type={passwordType}
                      label="Password"
                      name="password"
                    />
                    <div className="toggle-password" onClick={() => togglePwd()}>
                      {passwordVisibility && <VisibilityIcon />}
                      {!passwordVisibility && <VisibilityOffIcon className="visibility-off" />}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      select
                      name="status"
                      defaultValue="1"
                      label="Status"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem selected={true} key={1} value={1}>Active</MenuItem>
                      <MenuItem key={0} value={0}>Inactive</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="buttons-wrap">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>

              </Form>
            )}
          </Formik>

        </Popup>

        <Popup
          openPopup={viewEditPopup}
          setOpenPopup={setViewEditPopup}
          title={
            <div className="popup-title-bar">
              Update Partner
              <Button
                onClick={() => {
                  closeViewEditPopup();
                }}
                className="pop-close"
              >
                x
              </Button>
            </div>
          }
        >

          <Formik
            initialValues={initVal}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {

                let token = localStorage.getItem("token");

                //authentication
                axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
                  auth: token,
                })
                  .then(auth => {
                    if (auth.data == true) {
                      //set startdate time 

                      let modifacationDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

                      axios.put(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/update/${userId}`, {
                        username: values.username,
                        user_level: 'Partner',
                        // admin_id: values.admin_id,
                        salesperson_id: values.salesperson_id,
                        datetime_of_modification: modifacationDate,
                        email: values.email,
                        name: values.name,
                        company_name: values.company_name,
                        mobile: values.mobile,
                        address: values.address,
                        password: values.password_new,
                        status: values.status
                      })
                        .then(res => {
                          if (res.data == 1) {
                            //fetch list of partners
                            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/partners`).then((res) => {
                              const responseRecords = res.data;
                              setRecords(responseRecords);

                              let countE = Object.keys(responseRecords).length;
                              if (countE > 0) {
                                setShowPaginationE(false);
                              }
                              else {
                                setShowPaginationE(true);
                              }
                            });

                            //fetch list of salespersons list
                            axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/users/salespersons`).then((resSalesperson) => {
                              setRangesSalesperson(resSalesperson.data)
                            });

                            setSubmitting(false);
                            setViewEditPopup(false);
                            setUpdateNotify({
                              isOpen: true,
                              message: `Partner has been successfully modified.`,
                              type: "success",
                            });

                          }
                        })
                        .catch(error => {
                          setSubmitting(false);
                          // setViewEditPopup(false);
                          setUpdateNotify({
                            isOpen: true,
                            message: `Invalid Request.`,
                            type: "error",
                          });
                          console.log(error);
                        });
                    }
                    else {
                      localStorage.clear();
                      window.location.replace("/");
                    }
                  })
                  .catch(error => {
                    setSubmitting(false);
                    setViewEditPopup(false);
                    console.log(error)
                  });

              }, 500);
            }}
            //validation
            validate={values => {
              const errors: Partial<Values> = {};
              if (!values.username) {
                errors.username = 'Required';
              }
              // if (!values.user_level) {
              //   errors.user_level = 'Required';
              // }
              if (!values.name) {
                errors.name = 'Required';
              }
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (!values.name) {
                errors.name = 'Required';
              }
              if (!values.salesperson_id) {
                errors.salesperson_id = 'Required';
              }
              // if (!values.company_name) {
              //   errors.company_name = 'Required';
              // }
              // if (!values.mobile) {
              //   errors.mobile = 'Required';
              // }
              // if (!values.address) {
              //   errors.address = 'Required';
              // }

              return errors;
            }}
          >

            {({ submitForm, isSubmitting }) => (
              <Form className='viewEditForm partner'>
                {isSubmitting && <LinearProgress />}

                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Username"
                      name="username"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      select
                      name="salesperson_id"
                      label="Assign Salesperson"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      {rangesSalesperson.map((option) => (
                        <MenuItem key={option.user_id} value={option.user_id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Name"
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Email"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Company Name"
                      name="company_name"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Mobile"
                      name="mobile"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      label="Address"
                      name="address"
                    />
                  </Grid>
                  <Grid item xs={3} className="password-wrap">
                    <Field
                      component={TextFieldFormik}
                      type={passwordType}
                      label="Password"
                      name="password_new"
                    />
                    <div className="toggle-password" onClick={() => togglePwd()}>
                      {passwordVisibility && <VisibilityIcon />}
                      {!passwordVisibility && <VisibilityOffIcon className="visibility-off" />}
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      component={TextFieldFormik}
                      type="text"
                      select
                      name="status"
                      label="Status"
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    >
                      <MenuItem key={1} value={1}>Active</MenuItem>
                      <MenuItem key={0} value={0}>Inactive</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12} className="buttons-wrap">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>

        </Popup>


        <PageHeader
          title="Partner"
          subTitle="List of partners and affiliates"
          icon={<PeopleIcon />}
        />

        <div className="sec-wrap search-field">
          <div className="search-filter-wrap">
            <div className='flex-center'>
              <Toolbar>
                <TextField
                  label="Search Username"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
                  }}
                  onChange={handleSearch}
                />
              </Toolbar>

              <div className='flex-center'>
                <Select
                  label="Status"
                  placeholder='filter by status'
                  value={userStatusFilter}
                  onChange={e => setUserStatusFilter(e.target.value)}
                >
                  <MenuItem value={0}>All Users</MenuItem>
                  <MenuItem value={1}>Active</MenuItem>
                  <MenuItem value={2}>Inactive</MenuItem>
                </Select>
              </div>
            </div>

            <Button variant="contained" color="primary" onClick={() => addUser()} className="button-icon">
              <PersonAddIcon /> Add Partner
            </Button>
          </div>

          <div className="tbl-minpad">
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().filter(user => {
                  if (userStatusFilter === 0) {
                    return user
                  }
                  else if (userStatusFilter === 1) {
                    if (user.status === "1") {
                      return user
                    }
                  }
                  else {
                    if (user.status === "0") {
                      return user
                    }

                  }
                }).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.username}
                    </TableCell>
                    <TableCell>
                      {parseInt(row.status) === 1 ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.email}
                    </TableCell>
                    <TableCell>
                      {row.company_name}
                    </TableCell>
                    <TableCell>
                      {row.mobile}
                    </TableCell>
                    <TableCell>
                      {row.address}
                    </TableCell>
                    <TableCell>
                      {row.salesperson_name}({row.salesperson_username})
                    </TableCell>
                    <TableCell>
                      <a className="view-details" onClick={() => showDetailsPopup(row)}><VisibilityIcon /></a>
                      <a className="delete-enquiry" onClick={() => {
                        setConfirmDelete({
                          isOpen: true,
                          title:
                            "Are you sure you want to delete this partner?",
                          onConfirm: () => {
                            confirmDelEnquiry(row);
                          },
                        });
                      }}><DeleteIcon /></a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            {(showPaginationE == false) ? <TblPagination /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
          </div>
        </div>

      </div>
    </div>
  );

}
