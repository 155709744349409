import React, { useState, useEffect } from 'react';
import PageHeader from '../components/PageHeader';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SearchIcon from '@material-ui/icons/Search';
import axios from "axios";
import {
  TableBody,
  TableRow,
  TableCell,
  Button,
  Toolbar,
  TextField,
  Typography
} from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range';
import { format } from "date-fns";

//pages
import AllRecordsTbl from '../components/AllRecordsTbl';
import ClosedTbl from '../components/ClosedTbl';

//table head cells
const headCells = [
  { id: 'datetime_of_referral', label: 'Referred Date' },
  { id: 'company_name', label: 'Company Name' },
  { id: 'contact_name', label: 'Contact Person' },
  { id: 'mobile', label: 'Contact Number' },
  { id: 'closed_date', label: 'Closed Date' },
  { id: 'commission_amount', label: 'Comm. Amount' },
  { id: 'comm_paid_date', label: 'Comm. Paid Date' },
  { id: 'sales_amount', label: 'Sales Amount' },
]


export default function MyCommission() {
  let [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({ fn: items => { return items; } });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showFilter, setShowFilter] = useState(false); const {
    TblContainer,
    TblPagination,
    TblHead,
    recordsAfterPagingAndSorting
  } = AllRecordsTbl(records, headCells, filterFn);
  //pagination
  const [showPaginationC, setShowPaginationC] = useState(false);

  //search
  const handleSearch = e => {
    let target = e.target;
    setFilterFn({
      fn: items => {
        if (target.value == '') {
          return items;
        }
        else {
          return items.filter(x => x.company_name.toLowerCase().includes(target.value))
        }
      }
    })
  }

  //date filter
  const filterShow = () => {
    setShowFilter(!showFilter);
    setStartDate(new Date());
    setEndDate(new Date());

    let token = localStorage.getItem("token");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //fetch list of records
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry`).then((enquiryList) => {
            const responseRecords = enquiryList.data;

            //filter all status
            setRecords(responseRecords);

            let countE = Object.keys(responseRecords).length;
            if (countE > 0) {
              setShowPaginationC(false);
            }
            else {
              setShowPaginationC(true);
            }

          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }

  const DateFilter = () => {
    return (
      <div className='date-filter-wrap'>
        <DateRangePicker ranges={
          [selectionRange]}
          onChange={handleSelect}
        />
        <Button variant="contained" color="secondary" size="small" onClick={filterDates}>
          Search
        </Button>
      </div>
    )
  }

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  };

  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  const filterDates = () => {
    setShowFilter(!showFilter);

    //set startdate time 
    let modifiedStartDate = format(startDate, 'yyyy-MM-dd');
    modifiedStartDate = modifiedStartDate + " " + "00:00:00";
    modifiedStartDate = new Date(modifiedStartDate);

    //set enddate time 
    let modifiedEndDate = format(endDate, 'yyyy-MM-dd');
    modifiedEndDate = modifiedEndDate + " " + "23:59:59";
    modifiedEndDate = new Date(modifiedEndDate);

    //all enquiries
    let filteredRecords = records.filter(a => {
      let date = new Date(a.datetime_of_referral);
      return (date >= modifiedStartDate && date <= modifiedEndDate);
    });
    setRecords(filteredRecords);

    let countE = Object.keys(filteredRecords).length;
    if (countE > 0) {
      setShowPaginationC(false);
    }
    else {
      setShowPaginationC(true);
    }

  }

  useEffect(() => {
    const user_level = localStorage.getItem("user_level");
    const token = localStorage.getItem("token");
    const user_id = localStorage.getItem("user_id");

    //authentication
    axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/user/auth`, {
      auth: token,
    })
      .then(auth => {
        if (auth.data == true) {
          //fetch list of enquiriess
          axios.get(`${process.env.REACT_APP_API_DOMAIN_URL}/slim/api/enquiry`).then((res) => {
            const responseRecords = res.data;

            /***closed status***/
            let ClosedArray = responseRecords.filter(function (closed) {
              return closed.status == "Closed";
            });
            setRecords(ClosedArray);

            if (user_level == "Partner") {
              let filteredClosedEnquiry = [];
              for (let i = 0; i < ClosedArray.length; i++) {
                if (ClosedArray[i].user_id == user_id) {
                  filteredClosedEnquiry.push(ClosedArray[i]);
                }
              }
              setRecords(filteredClosedEnquiry);
              let countC = Object.keys(filteredClosedEnquiry).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);
            }
            else {
              let countC = Object.keys(ClosedArray).length;
              (countC > 0) ? setShowPaginationC(false) : setShowPaginationC(true);
            }

          });
        }
        else {
          localStorage.clear();
          window.location.replace("/");
        }
      })
      .catch(error => {
        console.log(error)
      });

  }, []);

  return (
    <div className="container">
      <PageHeader
        title="Commissions"
        subTitle="Closed deals with commissions"
        icon={<MonetizationOnIcon />}
      />

      <div className="sec-wrap search-field">
        <div className="search-filter-wrap">
          <Toolbar>
            <TextField
              label="Search Company"
              variant="outlined"
              InputProps={{
                startAdornment: <SearchIcon position="start">Kg</SearchIcon>,
              }}
              onChange={handleSearch}
            />
          </Toolbar>
          <Button variant="contained" color="primary" onClick={() => filterShow()} className="filter-dates">
            <DateRangeIcon /> Filter Dates
          </Button>
        </div>

        <div className="my-commission">
          {showFilter && <DateFilter />}
        </div>

        <div className="tbl-minpad">
          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.datetime_of_referral}
                  </TableCell>
                  <TableCell>
                    {row.company_name}
                  </TableCell>
                  <TableCell>
                    {row.contact_name}
                  </TableCell>
                  <TableCell>
                    {row.mobile}
                  </TableCell>
                  <TableCell>
                    {row.closed_date}
                  </TableCell>
                  <TableCell>
                    {row.commission_amount}
                  </TableCell>
                  <TableCell>
                    {row.comm_paid_date}
                  </TableCell>
                  <TableCell>
                    {row.sales_amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          {(showPaginationC == false) ? <TblPagination /> : <Typography variant="subtitle" component="div" className="no-data-notice"> No data available in table </Typography>}
        </div>
      </div>
    </div>
  );
}
